import React from 'react';
import './Loader.css';

const Loader = ({ className }) => (
  <div className={`loader${className ? ' ' + className : ''}`}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default Loader;
