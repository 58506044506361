import { STRUCTURE_SWAP, STRUCTURE_VANILLA, DEFAULT_UNIT, DEFAULT_UNIT_TYPE, ERROR_FETCH_CONTRACT_EXPIRY } from 'constants.js';
import { STRUCTURE as STRUCTURE_MINIFUTURE } from 'pages/minifuture/constants';
import { ofType } from 'redux-observable';
import { priceFormFailed, PRICING_SUBMIT_FORM } from 'redux/actions/price';
import { swapCardUpdate } from 'redux/actions/swap';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { priceFormValueSelector } from 'redux/epics/price/price-form';
import { cardError, extractContractData } from 'redux/epics/price/structures/swap/';
import { getSingleGraphQlError } from 'redux/epics/utils';
import { createSwapCardMutation } from 'redux/queries/swap';
import { from } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { fetchContractExpiryList } from 'pages/price/output/query';
import { SWAP_BULLET } from 'pages/price/output/asianSwap/constants';
import { tommorowDay } from 'pages/price/PriceForm/forms/accumulator/validators';

const MAP_EXPIRY_TYPE = new Map([
  [STRUCTURE_SWAP.toLowerCase(), STRUCTURE_SWAP.toLowerCase()],
  [STRUCTURE_VANILLA.toLowerCase(), STRUCTURE_VANILLA.toLowerCase()],
  [STRUCTURE_MINIFUTURE.toLowerCase(), STRUCTURE_SWAP.toLowerCase()],
]);

export const catchCardCreation = (commodityContract) => (
  catchError((error) => {
    const actions = [
      priceFormFailed(),
    ];
    const err = getSingleGraphQlError(error);
    if (err?.message || err.code === 403) {
      return from([
        ...actions,
        notificationErrorSimple(err.message),
      ])
    }

    return from([
      ...actions,
      cardError(commodityContract, 'retrieve'),
    ]);
  })
);

export const applyContract = (structure = STRUCTURE_SWAP.toLowerCase(), cardData, save) => {
  const { commodityCode, commodityContract, calendarSpread } = cardData;
  return from(
    fetchContractExpiryList({
      type: MAP_EXPIRY_TYPE.get(structure.toLowerCase()),
      commodityCode: commodityCode,
    })
  )
    .pipe(
      filter(payload => !!payload),
      switchMap((expiryData) => {
        if(expiryData.length) {
          const { bloombergTicker, contract: contractCode, expiry: contractExpiry, strike: strikeLevel, isSpot, isMetal } = expiryData[0];
          const cardPayload = {
            ...cardData,
            bloombergTicker,
            contractCode,
            contractExpiry: isSpot && isMetal ? tommorowDay() : contractExpiry,
            strikeLevel: structure === STRUCTURE_VANILLA.toLowerCase() && !strikeLevel ? 100 : strikeLevel,
          }

          if(calendarSpread){
            return from(
              fetchContractExpiryList({
                type: MAP_EXPIRY_TYPE.get(structure.toLowerCase()),
                commodityCode: commodityCode,
                contractExpiry,
              })
            ).pipe(
              filter(payload => !!payload),
              switchMap((expiryDataSecond) => {
                const { bloombergTicker: bloombergTickerSecond, contract: contractCodeSecond, expiry: contractExpirySecond } = expiryDataSecond[0];
                return save(structure, commodityContract, {
                  ...cardPayload,
                  contractCode: contractCodeSecond,
                  bloombergTickerSecond,
                  contractExpirySecond,
                });
              }),
              catchError(() => from([
                priceFormFailed(),
                notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY),
              ]))
            )
          }
          return save(structure, commodityContract, cardPayload);
        }
        return from([
          priceFormFailed(),
          notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY),
        ])
      }),
      catchError(() => from([
        priceFormFailed(),
        notificationErrorSimple(ERROR_FETCH_CONTRACT_EXPIRY),
      ]))
    )
}

const saveCard = (structure, commodityContract, cardData) => {
  return from(
    createSwapCardMutation(structure, cardData))
    .pipe(
      filter(payload => !!payload),
      map(({ status, currencyOptions, termCurrency, price, priceForCalcSpread, id, disableCompo, isSpot, isMetal }) => {
        return swapCardUpdate({
          ...cardData,
          id,
          price,
          priceForCalcSpread,
          termCurrency,
          currencyOptions,
          status,
          disableCompo,
          isSpot,
          isMetal,
        })
      }),
      catchCardCreation(commodityContract)
    )
}


export const submitPriceFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(PRICING_SUBMIT_FORM),
    filter(() => {
      const { structure, swapType, bulletStrip } = priceFormValueSelector(state$.value) || {}
      return structure === STRUCTURE_SWAP && swapType === SWAP_BULLET && !bulletStrip
    }),
    filter(({ payload }) => !!payload && Array.isArray(state$.value.price.pricings)),
    map(({ payload }) => extractContractData(state$.value.price.pricings, payload)),
    filter(contract => contract),
    debounceTime(200),
    switchMap(({ commodity: commodityContract, commodityCode, direction, quotedCurrency, calendarSpread }) => {

      const structure = priceFormValueSelector(state$.value).structure;
      const cardData = {
        calendarSpread,
        structure: STRUCTURE_SWAP.toLowerCase(),
        direction,
        commodityContract,
        commodityCode,
        quantity: 1,
        quotedCurrency,
        unit: DEFAULT_UNIT,
        unitType: DEFAULT_UNIT_TYPE,
      };

      return applyContract(structure, cardData, saveCard);
    })
  );

export default submitPriceFormEpic;
