import 'assets/scss/main.css';
import React from 'react';
import UserOnlineStatusProvider from 'components/common/online/UserOnlineStatus';
import Errors from 'components/errors/Errors';
import InfoMessages from 'components/infoMessages/InfoMessages';
import OldBrowserPage from 'components/oldBrowser/';
import oldBrowserDetect from 'components/oldBrowser/detectOldBrowser';
import { history } from 'components/router/BrowserRouter';
import BreakPointsProvider from 'components/theme/breakPoints/BreakPoints';
import { themeNameSelector } from 'components/theme/utils';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { DEFAULT_APP_TITLE } from './constants';
import RootRoutes from './pages/RootRoute';
import { ConnectedRouter } from 'connected-react-router'

const App = props => {
  const { themeName } = props;
  return (
    oldBrowserDetect() ?
      <OldBrowserPage />
      :
      <div className={themeName}>
        <BreakPointsProvider>
          <UserOnlineStatusProvider >
            <Helmet title={DEFAULT_APP_TITLE} />
            <InfoMessages />
            <Errors />
            <ConnectedRouter history={history}>
              <RootRoutes />
            </ConnectedRouter>
          </UserOnlineStatusProvider >
        </BreakPointsProvider>
      </div>
  );
};

const mapStateToProps = state => ({ themeName: themeNameSelector(state) });

App.propTypes = {
  themeName: PropTypes.string,
};

export default connect(mapStateToProps)(App);

