import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { createSelector } from "reselect";
import { combineEpics, ofType } from "redux-observable";
import {
  minifutureCardsUpdate,
  MINIFUTURE_CARDS_FETCH_MORE,
  MINIFUTURE_DATA_LOADED,
  MINIFUTURE_CARD_REFETCH,
} from "redux/actions/minifuture";
import {
  pricingResultsLoader,
  setStaticCursorData,
} from "redux/actions/price";
import {
  loadMinifutureCardsData,
} from "redux/queries/minifuture";
import { from, merge, of } from "rxjs";
import {
  delay,
  filter,
  map,
  switchMap,
} from "rxjs/operators";

export const loadMinifutureCardsEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_DATA_LOADED),
    filter(({ payload }) => payload && !isEmpty(payload.pricings)),
    switchMap(() => {
      const limit = state$.value.auth.account.cardsLimit;
      return from(loadMinifutureCardsData(null, limit)).pipe(
        switchMap((data) => {
          const actions = [
            minifutureCardsUpdate(data),
            setStaticCursorData(data),
            pricingResultsLoader(false),
          ];

          return from(actions);
        })
      );
    })
  );

const cardsSelector = createSelector(
  [(state) => state.cards, (state) => state.data],
  (cards, data) => {
    return Array.isArray(cards) && cards.map((card) => compareCard(card, data));
  }
);

const updateCardsState = (cards, data) => {
  return cardsSelector({
    cards,
    data,
  });
};

const compareCard = (card = {}, data = []) => {
  const {
    issueSize,
    totalSold,
    strike,
    contractExpiry,
    contractCode,
    marketingName,
    price,
    status
  } = data.find((i) => i.id === card.id);

  return {
    ...card,
    issueSize,
    totalSold,
    strike,
    contractExpiry,
    contractCode,
    marketingName,
    price,
    status,
  };
};

export const refetchMinifutureData = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_CARD_REFETCH),
    filter(
      () =>
        !isEmpty(
          state$.value.minifuture.trailPrice &&
            state$.value.minifuture.trailPrice.cards
        )
    ),
    switchMap(() => {
      const limit = state$.value.minifuture.trailPrice.cards.length;
      return from(loadMinifutureCardsData(null, limit)).pipe(
        switchMap((data) => {
          const cards = updateCardsState(
            state$.value.minifuture.trailPrice.cards,
            data.cards
          );
          const actions = [
            minifutureCardsUpdate({
              ...state$.value.minifuture.trailPrice,
              cards,
            }),
          ];

          return from(actions);
        })
      );
    })
  );

export const fetchMoreCardsEpic = (action$, state$) =>
  action$.pipe(
    ofType(MINIFUTURE_CARDS_FETCH_MORE),
    filter(
      ({ payload }) =>
        !!payload &&
        !!state$.value.minifuture &&
        !!state$.value.minifuture.trailPrice &&
        !!state$.value.minifuture.trailPrice.pageInfo
    ),
    map(({ payload }) => payload),
    switchMap(({ cursor, limit }) => {
      return from(loadMinifutureCardsData(cursor, limit)).pipe(
        filter((data) => data && Array.isArray(data.cards)),
        switchMap((data) => {
          const oldCards =
            state$.value.minifuture.trailPrice &&
            state$.value.minifuture.trailPrice.cards
              ? state$.value.minifuture.trailPrice.cards
              : [];
          const actions = [
            minifutureCardsUpdate({
              ...data,
              cards: uniqBy([...oldCards, ...data.cards], "id"),
            }),
            pricingResultsLoader(false),
          ];
          return from(actions).pipe(
            delay(180) //Animation time
          );
        })
      );
    })
  );

export default combineEpics(
  fetchMoreCardsEpic,
  loadMinifutureCardsEpic,
  refetchMinifutureData
);
