import React from 'react';
import PropTypes from 'prop-types';
import './markdown.css';

// @TODO: add slack markdown, create parser function
const Markdown = ({ value }) => {
  if (value.indexOf('> ') >= 0) {
    return <blockquote className="markdown-blockquote">{value.substr(2)}</blockquote>
  }
  return <p>{value}</p>
}

Markdown.propTypes = {
  value: PropTypes.string.isRequired,
}

export default Markdown;
