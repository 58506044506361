import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { combineEpics, ofType } from 'redux-observable';
import {
  neonCommodityUpdateAction,
  NEON_COMMODITY_UPDATE,
  neonCommodityLayoutUpdate,
  NEON_CARD_LAYOUT_UPDATE
} from 'redux/actions/neon';
import { PRICING_VANILLA_FORM_LOAD, PRICING_VANILLA_FORM_LOADED } from 'redux/actions/price';
import {
  PRICING_SWAP_DATA_LOADED,
  PRICING_SWAP_FORM_LOAD,
  swapCardCreate,
  swapCardDelete,
  swapCardsUpdate,
  swapCardUpdate
} from 'redux/actions/swap';
import { createSelector } from 'reselect';
import { from, merge } from 'rxjs';
import { bufferTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { STRUCTURE_SWAP, STRUCTURE_VANILLA, BUFFER_TIMEOUT, TRADE_DATE_FORMAT, CARD_STATUSES, STRUCTURE_ASIAN_SWAP, STRUCTURE_SWAP_BULLET_STRIP_VIEW } from 'constants.js';
import { subscribePricingProCards, subscribeSwapCommodityContracts } from '../../queries/swap';
import { formatSwapPrice } from 'redux/reducers/price';
import { priceFormDestroyFilter } from '../price/filters';
import { priceFormValueSelector } from '../price/price-form';
import { trailPricesFilter } from '../price/structures/swap';
import { distinctObjectChanges } from 'redux/epics/utils';
import { SWAP_ASIAN, SWAP_BULLET } from 'pages/price/output/asianSwap/constants';
import { CARD_CONTRACT_ACTIONS } from '../constants';

const mapFormToStructure = new Map([
  [`${PRICING_SWAP_DATA_LOADED}/${SWAP_BULLET}`, STRUCTURE_SWAP],
  [`${PRICING_SWAP_DATA_LOADED}/${SWAP_ASIAN}`, STRUCTURE_ASIAN_SWAP],
  [PRICING_VANILLA_FORM_LOADED, STRUCTURE_VANILLA]
]);

const neonSubscriptionEpic = (action$, state$) =>
  merge(
    action$.pipe(ofType(PRICING_SWAP_FORM_LOAD)),
    action$.pipe(ofType(PRICING_VANILLA_FORM_LOAD)),
  )
    .pipe(
      switchMap(() => {
        return merge(
          action$.pipe(ofType(PRICING_SWAP_DATA_LOADED)),
          action$.pipe(ofType(PRICING_VANILLA_FORM_LOADED)),
        ).pipe(
          switchMap(({ type }) => {
            const { swapType } = priceFormValueSelector(state$.value) || {};
            const key = swapType ? `${type}/${swapType}` : type;
            const structureStrategy = mapFormToStructure.get(key);

            return from(subscribeSwapCommodityContracts(structureStrategy)).pipe(
              filter(() => trailPricesFilter(state$)),
              bufferTime(BUFFER_TIMEOUT), // added to reduce the number of price rendering iterations, due to the performance issue when there can be 10-20 events per one second.
              filter((data) => !!data.length),
              distinctUntilChanged(distinctObjectChanges),
              map((commodity) => neonCommodityUpdateAction(commodity)),
              takeUntil(action$.pipe(filter(action => {
                return (priceFormValueSelector(state$.value) && ![STRUCTURE_SWAP, STRUCTURE_ASIAN_SWAP, STRUCTURE_VANILLA].includes(priceFormValueSelector(state$.value).structure)) || priceFormDestroyFilter(action)
              })))
            )
          }),
        )
      })
    );

const neonCardsLayoutSubscriptionEpic = (action$, state$) =>
  merge(
    action$.pipe(ofType(PRICING_SWAP_FORM_LOAD)),
  )
    .pipe(
      switchMap(() => {
        return merge(
          action$.pipe(ofType(PRICING_SWAP_DATA_LOADED)),
        ).pipe(
          switchMap(() => {
            return from(subscribePricingProCards()).pipe(
              map((payload) => {
                return neonCommodityLayoutUpdate(payload);
              })
            )
          }),
        )
      })
    );


const getStructureByCode = (code) => {
  if (code && code.length && (code[code.length - 1] === 'p' || code[code.length - 1] === 'c')) {
    return [STRUCTURE_VANILLA.toUpperCase(), '#f1ec94', '#242729'];
  }
  return [STRUCTURE_SWAP.toUpperCase(), '#4e3755', '#ffffff'];
}

const loggerSingleNeonUpdate = (card, commodity) => {
  const price = formatSwapPrice(commodity.price);
  if (price !== card.price) {
    const color = card.direction === 'seller' ? '#d9534f' : '#5cb85c';
    const direction = card.direction === 'seller' ? 'Sell' : 'Buy';
    const priceDirection = (price - card.price >= 0) ? '⏶' : '⏷';
    const structure = getStructureByCode(commodity.contract);
    console.log(`%cNEON%c::UPDATE %c ${formatStringLength(structure[0], 7)} %c ${formatStringLength(card.commodityContract, 15)} | Code: ${formatStringLength(commodity.contract, 23)} | Direction: ${formatStringLength(direction, 4)} | PRICE: %c${priceDirection}${price}`,
      'color: #f547c3', 'color: #242729', `background-color: ${structure[1]}; color: ${structure[2]}`, 'color: #242729', `color: ${color}`);
  }
}

const loggerNeonUpdate = (cards, commodity) => {
  if (isEmpty(cards) || (commodity && !commodity.contract)) return;
  const findCardIndex = cards.findIndex(({ contractCode, direction }) => (contractCode === commodity.contract && direction === commodity.direction));

  if (findCardIndex >= 0) {
    const card = cards[findCardIndex];
    loggerSingleNeonUpdate(card, commodity);
  }
}

const logPerfTaggedPriceUpdate = (commodity) => {
  if (!commodity || !commodity.meta) return;
  try {
    const meta = JSON.parse(commodity.meta);
    const now = new Date();
    const timesArr = [
      { tag: 'start', time: meta.quoteTime },
      { tag: 'proxy', time: meta.processTime },
      { tag: 'agileSub', time: meta.agileSub },
      { tag: 'agilePub', time: meta.agilePub },
      { tag: 'agileMarkup', time: meta.agileMarkup },
      { tag: 'client', time: now.toISOString() },
    ].map((x, i, arr) => ({
      ...x,
      delta: i > 0 ? (new Date(x.time) - new Date(arr[i - 1].time)) : 0
    }));

    console.info('redux epic tagged price update', {
      id: meta.taggedPriceId,
      contract: commodity.contract,
      userPrice: commodity.price,
      side: commodity.direction,
      quoteTime: meta.quoteTime,
      totalTime: now - new Date(meta.quoteTime),
      timesArr,
      meta,
    });
  } catch (e) {
    console.error("failed to process tagged price", e);
  }
};

const DEBUG_ENVS = [
  'dev',
  'demo'
];

export const isEnabledForEnv = (envs = DEBUG_ENVS) => {
  const env = window.API_HOST ? window.API_HOST.split('.')[1] : null;
  return envs.includes(env);
}

const cardsSelector = createSelector(
  [
    state => state.cards,
    state => state.contract,
  ],
  (cards, commodities) => {
    return Array.isArray(cards) && cards.map(card => compareCard(card, commodities));
  }
);

const formatStringLength = (str, length) => {
  length = length - str.length;
  return length > 0 ? str + ' '.repeat(length) : str;
}

const checkProperty = (commodity) => {
  if(commodity.hasOwnProperty('pricingMfFxContract')) return commodity.pricingMfFxContract
  if(commodity.hasOwnProperty('pricingSwapContract')) return commodity.pricingSwapContract
  if(commodity.hasOwnProperty('pricingAsianSwapContract')) return commodity.pricingAsianSwapContract
  return commodity.pricingFxCmContract
}

const compareCard = (card = {}, commodities = []) => {
  const rest = { ...card };
  const { contractCode, direction, quotedCurrency, termCurrency, baseCurrency, id, unitType, externalId, structure } = card;
  const isCompo = termCurrency && quotedCurrency !== termCurrency
  const isMfFx = !!(baseCurrency && termCurrency && externalId)
  const formattedCardTradeDate = moment(card.contractExpiry).format(TRADE_DATE_FORMAT);
  const getCommodity = commodities.filter(({ data }) => {
    const nestedItem = checkProperty(data)
    if(isMfFx) {
      const formattedCommodityTradeDate = moment(nestedItem.tradeDate).format(TRADE_DATE_FORMAT);
      return card.commodityContract &&
        card.commodityContract?.toLowerCase() === nestedItem.contract?.toLowerCase() &&
        card.direction === nestedItem.direction &&
        card.quantity === nestedItem.notional &&
        (card.baseCurrency !== card.quotedCurrency) === nestedItem.isContra &&
        moment(formattedCardTradeDate).isSame(formattedCommodityTradeDate)
    }
    if(data.hasOwnProperty('pricingFxCmContract') && card?.view === STRUCTURE_SWAP_BULLET_STRIP_VIEW) return id === nestedItem.cardId;
    if(data.hasOwnProperty('pricingAsianSwapContract')) return nestedItem && direction === nestedItem.direction && id === nestedItem.cardId; // for asian swap cards
    if(data.hasOwnProperty('pricingSwapContract') || data.hasOwnProperty('pricingMfFxContract')) return nestedItem && contractCode === nestedItem.contract && direction === nestedItem.direction; // for vanilla/mf_fx cards
    if(isCompo) return nestedItem && contractCode === nestedItem.contract && direction === nestedItem.direction && id === nestedItem.cardId // for compo cards
    if(nestedItem.isExtendedSwap) return nestedItem && contractCode === nestedItem.contract && direction === nestedItem.direction && id === nestedItem.cardId && unitType === nestedItem.unitType // for swap metal and swap with unit cards
    return nestedItem && contractCode === nestedItem.contract && direction === nestedItem.direction && isNull(nestedItem.cardId) // for swap cards
  })

  if(!getCommodity.length) return rest;
  const commodity = checkProperty(getCommodity[getCommodity.length - 1]['data']);

  // only dev logs
  // logPerfTaggedPriceUpdate(commodity);
  // if (isEnabledForEnv(DEBUG_ENVS)) {
  //     loggerSingleNeonUpdate(card, commodity);
  // }

  // for asian swap cards
  if (
    commodity && id === commodity.cardId &&
    card.status === CARD_STATUSES.APPROVED &&
    structure === SWAP_ASIAN
  ) {
    const price = formatSwapPrice(commodity.price);
    const { avgPrice, legsPrices, requestInfo } = commodity;
    if (card.price !== price) return { ...rest, price, avgPrice, legsPrices, requestInfo };
  }

  // for compo cards
  if (commodity && contractCode === commodity.contract && direction === commodity.direction && quotedCurrency !== termCurrency && id === commodity.cardId) {
    const price = formatSwapPrice(commodity.price);
    const { quoteId, fxPrice: rate, cmPrice, priceForCalcSpread, fxPriceForCalcSpread, cmPriceForCalcSpread, avgPrice, legsPrices, requestInfo } = commodity;
    if (card.price !== price) return { ...rest, quoteId, price, cmPrice, rate, priceForCalcSpread, fxPriceForCalcSpread, cmPriceForCalcSpread, avgPrice, legsPrices, requestInfo };
  }

  // for vanilla/minifuture/swap cards
  if (commodity && contractCode === commodity.contract && direction === commodity.direction && (!termCurrency || quotedCurrency === termCurrency)) {
    const { avgPrice, legsPrices, requestInfo } = commodity;
    const price = formatSwapPrice(commodity.price);
    if (card.price !== price) return { ...rest, price, priceForCalcSpread: commodity.priceForCalcSpread, avgPrice, legsPrices, requestInfo };
  }

  // for minifuture/fx cards
  const formattedCommodityTradeDate = moment(commodity.tradeDate).format(TRADE_DATE_FORMAT);
  if (
    commodity && card.commodityContract &&
    card.commodityContract?.toLowerCase() === commodity.contract?.toLowerCase() &&
    card.direction === commodity.direction &&
    card.quantity === commodity.notional &&
    (card.baseCurrency !== card.quotedCurrency) === commodity.isContra &&
    moment(formattedCardTradeDate).isSame(formattedCommodityTradeDate) &&
    card.status === CARD_STATUSES.APPROVED
  ) {
    const quoteId = commodity.quoteId;
    const price = formatSwapPrice(commodity.price);
    const priceForCalcSpread = commodity.priceForCalcSpread
    if (card.price !== price || card.quoteId !== quoteId) return { ...rest, price, quoteId, priceForCalcSpread, fwdPoints: commodity.fwdPoints };
  }

  return rest;
}

export const updateCardsState = (cards, contract) => {
  return cardsSelector({
    cards,
    contract,
  });
}

export const neonUpdatesEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(NEON_COMMODITY_UPDATE),
      filter(() => priceFormValueSelector(state$.value) && (
        priceFormValueSelector(state$.value).structure === STRUCTURE_SWAP || priceFormValueSelector(state$.value).structure === STRUCTURE_VANILLA
      )),
      filter(({ payload }) => !isEmpty(state$.value.price.trailPrice.cards) && !isEmpty(payload)),
      map(({ payload }) => payload),
      map((subDataBufferArray) => {
        const cards = updateCardsState(state$.value.price.trailPrice.cards, subDataBufferArray);
        return swapCardsUpdate({
          ...state$.value.price.trailPrice,
          cards
        });
      }),
    );

const generateCardData = (cardsContract, state$) => {
  const {
    bloombergTicker,
    cardId,
    quantity,
    type,
    commodityCode,
    direction,
    unitType,
    buffer,
    contractCode,
    contractExpiry,
    contractExpirySecond,
    notionalCurrency,
    defaultCurrency,
    tradeDate,
    status,
    notional,
    isMetal,
    isSpot,
    pricingType,
  } = cardsContract;

  const pricing = state$.value.price.pricings;
  const price = pricing.find((price)=> price.commodityCode === commodityCode);

  const card = {
    commodityContract: price?.commodity,
    bloombergTicker,
    id: cardId,
    quantity: quantity || notional,
    structure: type,
    commodityCode,
    contractCode,
    contractExpirySecond,
    direction,
    unitType,
    unit: price?.units.find((unit)=> unit.unitType === unitType)?.value,
    termCurrency: notionalCurrency || defaultCurrency,
    quotedCurrency: defaultCurrency,
    calendarSpread: !!contractExpirySecond,
    disableCompo: !buffer,
    tradeDate,
    status,
    contractExpiry,
    isSpot,
    isMetal,
    pricingType,
  }

  return card
}

export const neonCardsLayoutUpdatesEpic = (action$, state$) =>
  action$
    .pipe(
      ofType(NEON_CARD_LAYOUT_UPDATE),
      filter(() => {
        return priceFormValueSelector(state$.value) && (
          priceFormValueSelector(state$.value).structure === STRUCTURE_SWAP
        );
      }),
      map(({ payload }) => payload),
      filter(({ data }) => data.cardsContract.type === SWAP_BULLET && state$.value.price.swapType === SWAP_BULLET),
      filter(() => state$.value.price.trailPrice),
      map((subDataBufferArray) => {
        const { cardsContract } = subDataBufferArray.data;
        const trailPrice = state$.value.price.trailPrice;
        const cardState = trailPrice ? trailPrice.cards : [];
        const currentCardState = cardState && cardState.find((card)=> card.id === cardsContract.cardId)
        if (cardsContract.action === CARD_CONTRACT_ACTIONS.DELETE && currentCardState) {
          return swapCardDelete(currentCardState.id)
        }

        if (!currentCardState && cardsContract.action === CARD_CONTRACT_ACTIONS.ADD && cardsContract.commodityCode) {
          return swapCardCreate(generateCardData(cardsContract, state$));
        }

        if (cardsContract.action === CARD_CONTRACT_ACTIONS.UPDATE && cardsContract.commodityCode) {
          return swapCardUpdate({
            ...currentCardState,
            ...generateCardData(cardsContract, state$),
            commodityContract: currentCardState?.commodityContract,
          })
        }

        return swapCardsUpdate({ cards: cardState });
      }),
    );

export default combineEpics(
  neonSubscriptionEpic,
  neonUpdatesEpic,
  neonCardsLayoutSubscriptionEpic,
  neonCardsLayoutUpdatesEpic,
)
