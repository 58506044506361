import gql from 'graphql-tag';

export const ACCUMULATOR_MINI_TRADE_FLEXIBLE_MUTATION = gql`mutation accumulatorTradeMiniWithoutPricingMutation(
  $clientId: String!
  $locationId: String
  $customerId: String
  $notional: Float!
  $startDate: DateTime
  $endDate: DateTime
  $pricingCardId: Int!
  $pricingTemplateId: Int!
  $commodityContractId: Int!
  $priceReference: Float!
  $updateState: AccumulatorPricingCardStateEnums!
  $comment: String
  $msaFixings: Int!
  $alreadyTraded: Boolean
) {
  accumulatorTradeMiniWithoutPricing (
    clientId: $clientId
    locationId: $locationId
    customerId: $customerId
    notional: $notional
    startDate: $startDate
    endDate: $endDate
    pricingCardId: $pricingCardId
    pricingTemplateId: $pricingTemplateId
    commodityContractId: $commodityContractId
    priceReference: $priceReference
    updateState: $updateState
    comment: $comment
    msaFixings: $msaFixings
    alreadyTraded: $alreadyTraded
  )
}`;
