import React, { useEffect, useState } from 'react';
import { GRAPHQL_NETWORK_ONLY } from 'components/graphql/constants';
import { STRUCTURE_ACCUMULATOR_MINI } from 'constants.js';
import { GET_MARKET_DATA_BY_BLOOMBERG } from 'pages/price/ProductChart/accumulator/query';
import PrintPriceContent from 'pages/print/price/PrintPriceContent';
import { graphql, withApollo } from 'react-apollo';
import { compose } from 'redux';
import { roundToClosest } from 'redux/epics/price/mini/utils';
import { PRINT_PRICINIG_MINI_FACT_SHEET_QUERY } from './query';
import 'pages/print/price/PrintPrice.css';

const mapPricingMiniFields = {
  'accLevel': 'strikeLevel',
  'barrLevel': 'barrierLevel',
};

const pricingMiniData = (data = {}, { commodityContract: commodityContractProp, marketDataId }) => {
  const {
    underlyingReference: bloombergTicker,
    solvedValues = [],
    tradedCurrency: quotedCurrency,
    futTradingUnits,
    userInputs = [],
    roundingRule,
    card: {
      title
    }
  } = data;

  const accumulatorUnderlyings = data?.accumulatorUnderlyings || {};
  const description = data?.description || {};

  const commodityContract = accumulatorUnderlyings?.commodityContract || commodityContractProp;
  const quoteUnits = accumulatorUnderlyings?.quoteUnits;
  const { remainderFeature = 'none', barrierType } = description;

  let result = {
    ...data,
    quotedCurrency,
    futTradingUnits,
    quoteUnits,
    remainderFeature,
    bloombergTicker,
    commodityContract,
    barrierType,
    accumulatorUnderlyings,
    description: {
      ...description,
      product: title
    },
    marketData: { id: marketDataId },
  };

  const solveFields = [];
  if (Array.isArray(solvedValues)) {
    solvedValues.forEach(({ field, value }) => {
      const name = mapPricingMiniFields[field] || field;
      const roundedValue = roundToClosest(value, roundingRule);
      result[name] = Number(roundedValue);
      if (name === 'strikeLevel') result['accumulationLevel'] = Number(roundedValue);
      solveFields.push(field);
    });
  }

  if (Array.isArray(userInputs)) {
    userInputs
      .filter(({ field }) => !solveFields.includes(field))
      .forEach(({ field, value }) => {
        const name = mapPricingMiniFields[field] || field;
        if (name === 'strikeLevel') result['accumulationLevel'] = value;
        result[name] = value;
      });
  }

  return result;
}

const FactSheetPrint = props => {
  const { 
    onClose,
    data: { factSheetPricingMini, loading },
    bloombergTicker,
    client,
    commodityContract,
    premium,
    premiumField,
    setPremiumAction,
    setNotionalAction
  } = props;

  const [isLoading, setLoader] = useState(true);
  const [marketDataId, setMarketDataId] = useState(null);
  const data = factSheetPricingMini ? pricingMiniData(factSheetPricingMini, { commodityContract, marketDataId }) : {};

  const getMarketDataId = async (bloombergTicker) => {
    const response = await client.query({
      query: GET_MARKET_DATA_BY_BLOOMBERG,
      variables: { bloombergTicker },
      fetchPolicy: 'cache-first',
    }).catch(e => {
      console.log(e);
    });
    return response;
  }

  const loadData = async () => {
    if (bloombergTicker) {
      const { data: { getMarketDataByBloombergTicker } = {} } = await getMarketDataId(bloombergTicker);
      if (getMarketDataByBloombergTicker?.id) setMarketDataId(getMarketDataByBloombergTicker.id);
    }
    setLoader(false)
  };

  useEffect(() => {
    loadData();
  }, []);

  return <PrintPriceContent 
          onClose={onClose} 
          {...data} 
          loading={loading || isLoading} 
          type={STRUCTURE_ACCUMULATOR_MINI.toLowerCase()} 
          premium={premium} 
          premiumField={premiumField} 
          setPremiumAction={setPremiumAction} 
          setNotionalAction={setNotionalAction}
         />
};

const PRINT_PRICINIG_MINI_FACT_SHEET_QUERY_OPTIONS = {
  options: ({ trailId: id, notional }) => ({ variables: { id, notional }, fetchPolicy: GRAPHQL_NETWORK_ONLY })
};

export default compose(
  graphql(PRINT_PRICINIG_MINI_FACT_SHEET_QUERY, PRINT_PRICINIG_MINI_FACT_SHEET_QUERY_OPTIONS),
  withApollo,
)(FactSheetPrint);
