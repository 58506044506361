import PropTypes from 'prop-types';
import React from 'react';
import { PRICING_FORM } from 'redux/actions/price';
import FormGroup from './FormGroup';
import InputAnimation from './inputs/InputAnimation';
import InputClearWrapper from './inputs/InputClearWrapper';
import renderInput from './RenderInput';
import 'react-datepicker/dist/react-datepicker.css';

export const InlineInput = props => {
  const {
    label,
    type,
    input,
    meta,
    clearBtn,
    showAnimation,
    ...rest
  } = props;
  const animation = (meta?.form === PRICING_FORM) || showAnimation;
  const component = renderInput({
    type,
    meta,
    ...input,
    ...rest,
  });
  return (
    <FormGroup {...props}>
      {clearBtn ?
        <InputClearWrapper form={meta.form} name={input.name} >
          {component}
        </InputClearWrapper>
        : component}
      {animation ? <InputAnimation value={input.value} active={!meta.active} name={input.name} /> : null}
    </FormGroup>
  );
};

InlineInput.defaultProps = {
  type: 'text'
};

InlineInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.node,
  onChangeFilter: PropTypes.func,
};

export default InlineInput;
