import gql from 'graphql-tag';

export const STRUCTURED_PRODUCTS_HISTORY_QUERY_ITEM_FRAGMENT = gql`fragment structuredProductsHistoryConnectionQueryFragment on StructuredProductsHistoryType {
  autocallId
  capitalId
  structure
  snowball
  stepDown
  issuer
  currency
  underlyings {
    id
    underlyingAsset
    title: bloombergTicker
  }
  maturity
  autocall
  frequency
  firstObservationIn
  autocallTriggerLevel
  couponType
  memoryCoupon
  couponTriggerLevel
  solveFor
  couponLevel
  strikeLevel
  downsideLeverage
  barrierType
  barrierLevel
  step
  valuationDate
  reportDate
  marketData
  corrDelta
  createdAt
  updatedAt
  reoffer
  tag
  productAnalysis {
    bloombergTicker
    delta
    vega
  }
  type
  basketType
  capitalProtectionLevel
  optionType
  strikeLevel
  participationLevel
  capType
  barrierType
  capLevel
  barrierLevel
  rebateLevel
  optionType2
  strikeLevel2
  participationLevel2
  capType2
  barrierType2
  capLevel2
  barrierLevel2
  isAdditinalSection: addOption
  noCouponPaidAtMaturity
  strikeDate
  observationLag
  paymentLag
  notional
  legalShape
  floatingCurve
  spread
  couponDirection
  oneStar
  oneStarThreshold
}
`;

export const STRUCTURED_PRODUCTS_HISTORY_QUERY = gql`query structuredProductsHistoryConnectionQuery (
  $cursor: String
  $sorter: [StructuredProductsHistorySorterType]
  $filter: [StructuredProductsHistoryFilterType]
  $total: Int
) {
  structuredProductsHistoryConnection (
    first: $total
    after: $cursor
    sorter: $sorter
    filter: $filter
  ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...structuredProductsHistoryConnectionQueryFragment
        }
        cursor
      }
      total
    }
}
${STRUCTURED_PRODUCTS_HISTORY_QUERY_ITEM_FRAGMENT}`;

export const getFilterStructuredProductsHistoryQueryString = (fieldName) => gql`query structuredProductsHistoryFilterValuesQuery {
    structuredProductsHistoryFilterValues (
      fieldName: ${fieldName}
  )
}`;
