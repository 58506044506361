import { MKT, ORDER_TYPES_DEFAULT, TIME_IN_FORCE_OPTIONS } from 'components/trade/constants.js';

export const CARD_HEIGHT = 477;
export const STATE_NAME = 'minifuture';

export const GQ_VIEW = {
  lv_rv: 'lv_rv',
  buy_sell: 'buy_sell',
};

export const STRUCTURE_VIEW = [
  { value: GQ_VIEW.lv_rv, label: "LV/RV" },
  { value: GQ_VIEW.buy_sell, label: "Buy/Sell" }
];

export const GQ_DIRECTION_FIRST = {
  buy: 'buy',
  sell: 'sell'
};

export const GQ_DIRECTION_SECOND = {
  lv: 'lv',
  rv: 'rv'
};

export const DIRECTION_FIRST = [
  { value: GQ_DIRECTION_FIRST.buy, label: "Buy" },
  { value: GQ_DIRECTION_FIRST.sell, label: "Sell" }
];

export const DIRECTION_SECOND = [
  { value: GQ_DIRECTION_SECOND.lv, label: "LV" },
  { value: GQ_DIRECTION_SECOND.rv, label: "RV" }
];

export const STRUCTURE = 'Minifuture';

export const DEFAULT_STRUCTURE_VIEW = STRUCTURE_VIEW[0].value;

export const MINIFUTURE_ORDERS_FORM_INITIAL_VALUES = {
  orderType: ORDER_TYPES_DEFAULT.value,
  priceMkt: MKT,
  stopPrice: null,
  expiryDate: null,
  maxExpiryDate: null,
};

export const TYPE = [
  { value: GQ_DIRECTION_SECOND.rv, label: "Reverse" },
  { value: GQ_DIRECTION_SECOND.lv, label: "Leverage" }
];
