import apolloClient from 'apollo.js';
import { getAuthToken } from 'auth';
import { GRAPHQL_NETWORK_ONLY, GRAPHQL_CACHE_FIRST } from 'components/graphql/constants';
import { STRUCTURE_SWAP, DEFAULT_LIMIT_FOR_LOAD_CARDS, STRUCTURE_MINIFUTURE, STRUCTURE_ASIAN_SWAP } from 'constants.js';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { pricingResultsLoader } from 'redux/actions/price';
import store from 'store.js';
import { mapNodes } from 'utils/';
import { notificationPriceFailLoadAllData } from '../alerts/actions';
import { SWAP_BULLET, SWAP_ASIAN } from 'pages/price/output/asianSwap/constants';

export const SWAP_LOAD_FORM_DATA = gql`query loadAllDataSwap(
  $swapType: String
) {
  pricingSwapContractsConnection(
    swapType: $swapType
  ) {
    structureStatus
    enableCalendarSpread
    commodities {
      commodity
      commodityCode
      quotedCurrency
      units {
        label
        value
        unitType
      }
    }
  }
}`;

export const LOAD_SWAP_CURRENCY_OPTIONS_QUERY = gql`query loadSwapCurrencyOptions {
  currencyOptionsConnection {
    quotedCurrency
    currencyOptions {
      label
      value
      deliverable
      fxCode
    }
  }
}`;

export const LOAD_ASIAN_SWAP_UNDERLYING_OPTIONS_QUERY = gql`query loadAsianSwapUnderlyingContractsOptions {
  AsianUnderlyingContractsConnection{
    value
    label
  }
}`;

export const LOAD_ASIAN_SWAP_SETTLEMENT_OPTIONS_QUERY = gql`query loadAsianSwapAsianSettlementOptions {
  AsianSettlementOptionsConnection{
    value
    label
  }
}`;

const mapSwapContractsResult = (res) => {
  const data = res.data && res.data.pricingSwapContractsConnection;
  return Array.isArray(data.commodities) && data.commodities;
}

const flatSwapCurrencyResult = (res) => {
  const data = res.data && res.data.currencyOptionsConnection;

  if (isEmpty(data)) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    store.dispatch(pricingResultsLoader(false));
    return;
  }
  return Array.isArray(data) && data.reduce((obj, { quotedCurrency, currencyOptions}) => ({ ...obj, [quotedCurrency]: currencyOptions }), {});
}

const flatAsianSwapContractsResult = (res) => {
  const data = res.data?.AsianUnderlyingContractsConnection;

  if (isEmpty(data)) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    store.dispatch(pricingResultsLoader(false));
    return;
  }
  return Array.isArray(data) ? data : [];
}

const flatAsianSwapSattlementResult = (res) => {
  const data = res.data?.AsianSettlementOptionsConnection;

  if (isEmpty(data)) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    store.dispatch(pricingResultsLoader(false));
    return;
  }
  return Array.isArray(data) ? data : [];
}

export const loadSwapData = async (swapType = SWAP_BULLET) => {
  store.dispatch(pricingResultsLoader(true));
  try {
    const requests = [
      apolloClient.query({
        query: SWAP_LOAD_FORM_DATA,
        fetchPolicy: GRAPHQL_NETWORK_ONLY,
        variables: {
          swapType
        }
      }),
      apolloClient.query({
        query: LOAD_SWAP_CURRENCY_OPTIONS_QUERY,
        fetchPolicy: GRAPHQL_NETWORK_ONLY,
      })
    ];

    if (swapType === SWAP_ASIAN) {
      requests.push(
        apolloClient.query({
          query: LOAD_ASIAN_SWAP_UNDERLYING_OPTIONS_QUERY,
          fetchPolicy: GRAPHQL_CACHE_FIRST,
        })
      );
      requests.push(
        apolloClient.query({
          query: LOAD_ASIAN_SWAP_SETTLEMENT_OPTIONS_QUERY,
          fetchPolicy: GRAPHQL_CACHE_FIRST,
        })
      );
    }

    const [contractsResult, currencyResult, asianContractsResult = null, asianSettlementResult = null] = await Promise.all(requests).catch((error) => {
      store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
      store.dispatch(pricingResultsLoader(false));
      console.log(error);
    });


    const pricings = mapSwapContractsResult(contractsResult);
    const currencyOptions = flatSwapCurrencyResult(currencyResult)
    const asianUnderlyingContracts = asianContractsResult ? flatAsianSwapContractsResult(asianContractsResult) : [];
    const asianSettlementOptions = asianSettlementResult ? flatAsianSwapSattlementResult(asianSettlementResult) : [];

    return { swapType, pricings, currencyOptions, asianUnderlyingContracts, asianSettlementOptions, enableCalendarSpread: contractsResult?.data?.pricingSwapContractsConnection?.enableCalendarSpread };
  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    store.dispatch(pricingResultsLoader(false));
    console.log(e);
  }
  return { pricings: [], currencyOptions: {}, enableCalendarSpread: false };
};

export const NORMAL_CARDS_SUBSCRIPTION = (operation) => gql`subscription ${operation}($authorization: String!) {
  pricingSwapContract (authorization: $authorization) {
    contract
    price
    direction
    meta
    priceForCalcSpread
  }
}`;

export const CARDS_CONTRACT = gql`subscription PricingPro($authorization: String!) {
  cardsContract (authorization: $authorization) {
    userId
    direction
    bloombergTicker
    commodityCode
    contractExpiry
    cardId
    unitType
    type
    buffer
    defaultCurrency
    notionalCurrency
    tradeDate
    action
    status
    quantity
    notional
    expiryDate
    contractCode
    contractExpiry
    contractExpirySecond
    termCurrency
    status
    isMetal
    isSpot
    pricingType
  }
}`;

export const SWAP_COMBO_CONTRACT_SUBSCRIPTION = gql`subscription Swap($authorization: String!) {
  pricingFxCmContract (authorization: $authorization) {
    contract
    price
    direction
    cardId
    quoteId
    fxPrice
    cmPrice
    notional
    meta
    isExtendedSwap
    priceForCalcSpread
    fxPriceForCalcSpread
    cmPriceForCalcSpread
    unitType
    avgPrice
    legsPrices {
      month
      cmPrice
      notional
      quoteId
      fxRate
      fxSpreadRate
      price
      ticker
    }
    requestInfo {
      commodity
      totalSize
      currencyPair
      direction
      unitType
      underlyingContracts
      settlement
      months
    }
  }
}`;

export const MF_FX_CONTRACT_SUBSCRIPTION = gql`subscription Minifuture($authorization: String!) {
  pricingMfFxContract (authorization: $authorization) {
    contract
    price
    direction
    meta
    priceForCalcSpread
    tradeDate
    quoteId
    limit
    isContra
    notional
    fwdPoints
  }
}`;

export const ASIAN_SWAP_CONTRACT_SUBSCRIPTION = gql`subscription AsianSwap($authorization: String!) {
  pricingAsianSwapContract (authorization: $authorization) {
    cardId
    price
    avgPrice
    legsPrices {
      month
      cmPrice
      notional
      quoteId
      fxRate
      fxSpreadRate
    }
    direction
    meta
    limit
    notional
    requestInfo {
      commodity
      totalSize
      currencyPair
      direction
      unitType
      underlyingContracts
      settlement
      months
    }
  }
}`;

const getSubscriptionQuery = (normalCardsType) => {
  switch (normalCardsType) {
    case STRUCTURE_SWAP: return SWAP_COMBO_CONTRACT_SUBSCRIPTION
    case STRUCTURE_MINIFUTURE: return MF_FX_CONTRACT_SUBSCRIPTION
    case STRUCTURE_ASIAN_SWAP: return ASIAN_SWAP_CONTRACT_SUBSCRIPTION
    default: return NORMAL_CARDS_SUBSCRIPTION(normalCardsType)
  }
}

export const subscribePricingProCards = () => {
  const token = getAuthToken();
  return apolloClient.subscribe({
    query: CARDS_CONTRACT,
    variables: {
      authorization: token
    }
  });
};

export const subscribeSwapCommodityContracts = (normalCardsType) => {
  const token = getAuthToken();
  return apolloClient.subscribe({
    query: getSubscriptionQuery(normalCardsType),
    variables: {
      authorization: token
    }
  });
};

const PRICING_SWAP_CALCULATE_PRICE_MUTATION = gql`mutation pricingSwapCalculatePriceMutation(
  $structure: String!
  $direction: PricingSwapDirectionEnumType!
  $contractCode: String!
  $commodityCode: String!
  $commodityContract: String!
  $contractExpiry: String
  $bloombergTicker: String
  $quotedCurrency: String!
  $contractExpirySecond: String
  $bloombergTickerSecond: String
) {
  pricingSwapCalculatePrice (
    structure: $structure
    direction: $direction
    contractCode: $contractCode
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    contractExpiry: $contractExpiry
    bloombergTicker: $bloombergTicker
    quotedCurrency: $quotedCurrency
    contractExpirySecond: $contractExpirySecond
    bloombergTickerSecond: $bloombergTickerSecond
  ) {
    id
    price
    termCurrency
    quotedCurrency
    status
    disableCompo
    priceForCalcSpread
    isSpot
    isMetal
  }
}`;

export const createSwapCardMutation = (structure,
  {
    direction,
    contractCode,
    commodityCode,
    commodityContract,
    contractExpiry,
    contractExpirySecond,
    bloombergTicker,
    bloombergTickerSecond,
    quotedCurrency
  }) => {
  return apolloClient.mutate({
    mutation: PRICING_SWAP_CALCULATE_PRICE_MUTATION,
    variables: {
      structure,
      direction,
      contractCode,
      commodityCode,
      commodityContract,
      contractExpiry,
      contractExpirySecond,
      bloombergTicker,
      bloombergTickerSecond,
      quotedCurrency
    },
  })
    .then(response => response.data.pricingSwapCalculatePrice);
}

const PRICING_SWAP_UPDATE_PRICE_MUTATION = gql`mutation pricingSwapCalculatePriceUpdateMutation(
  $id: String!
  $quantity: Float
  $structure: String
  $direction: PricingSwapDirectionEnumType
  $contractCode: String
  $commodityCode: String
  $commodityContract: String
  $quotedCurrency: String
  $contractExpiry: String
  $contractExpirySecond: String
  $bloombergTicker: String
  $bloombergTickerSecond: String
  $termCurrency: String
  $tradeDate: String
  $forceTradeDate: Boolean
  $status: String
  $fxCode: String
  $unit: String
  $unitType: PricingUnitEnumType
) {
  pricingSwapCalculatePriceUpdate (
    id: $id,
    quantity: $quantity
    structure: $structure
    direction: $direction
    contractCode: $contractCode
    commodityCode: $commodityCode
    commodityContract: $commodityContract
    quotedCurrency: $quotedCurrency
    contractExpiry: $contractExpiry
    contractExpirySecond: $contractExpirySecond
    bloombergTicker: $bloombergTicker
    bloombergTickerSecond: $bloombergTickerSecond
    termCurrency: $termCurrency
    tradeDate: $tradeDate
    forceTradeDate: $forceTradeDate
    status: $status
    fxCode: $fxCode
    unit: $unit
    unitType: $unitType
  ) {
    id
    price
    tradeDate
    status
    fxBankHolidays
    disableCompo
    priceForCalcSpread
  }
}`;

export const updateSwapCardMutation = (
  structure,
  {
    id,
    direction,
    contractCode,
    commodityCode,
    commodity: commodityContract,
    contractExpiry,
    contractExpirySecond,
    quantity = 1,
    quotedCurrency,
    bloombergTicker,
    bloombergTickerSecond,
    termCurrency,
    tradeDate,
    forceTradeDate,
    status,
    fxCode,
    unit,
    unitType,
  }) => {
  const variables = {
    structure,
    id,
    direction,
    contractCode,
    commodityCode,
    commodityContract,
    contractExpiry,
    contractExpirySecond,
    quantity,
    quotedCurrency,
    bloombergTicker,
    bloombergTickerSecond,
    termCurrency,
    tradeDate,
    forceTradeDate,
    status,
    fxCode,
    unit,
    unitType,
  };
  return apolloClient.mutate({
    mutation: PRICING_SWAP_UPDATE_PRICE_MUTATION,
    variables,
  })
    .then(response => response.data.pricingSwapCalculatePriceUpdate);
}

const PRICING_SWAP_DELETE_PRICE_MUTATION = gql`mutation pricingSwapCalculatePriceDeleteMutation(
  $id: String!
) {
  pricingSwapCalculatePriceDelete (
    id: $id
  )
}`;

export const deleteSwapCardMutation = id => {
  return apolloClient.mutate({
    mutation: PRICING_SWAP_DELETE_PRICE_MUTATION,
    variables: {
      id
    },
  })
    .then(response => response.data);
}

const PRICINIG_SWAP_HISTORY = gql`query pricingSwapHistoryQuery (
    $cursor: String
    $limit: Int
  ) {
  pricingSwapHistoryConnection (
    after: $cursor
    first: $limit
  ) {
    edges {
      node{
        id
        direction
        contractCode
        commodityContract
        commodityCode
        price
        contractExpiry
        quantity
        structure
        bloombergTicker
        termCurrency
        quotedCurrency
        tradeDate
        status
        fxCode
        fxBankHolidays
        unit
        unitType
        disableCompo
        bloombergTickerSecond
        contractExpirySecond
        priceForCalcSpread
        isSpot
        isMetal
        view
        firstMonth
        lastMonth
        legs {
          contractExpiry
          notional
          expiryDate
        }
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}`;

export const loadSwapCardsData = async (cursor = null, limit) => {
  try {
    let resp = await apolloClient.query({
      query: PRICINIG_SWAP_HISTORY,
      fetchPolicy: GRAPHQL_NETWORK_ONLY,
      variables: {
        cursor,
        limit: limit || DEFAULT_LIMIT_FOR_LOAD_CARDS
      }
    })
      .then((res) => {
        const cards = mapNodes(res.data.pricingSwapHistoryConnection);
        const pageInfo = res.data.pricingSwapHistoryConnection.pageInfo;
        return { cards, pageInfo };
      });
    return resp;

  } catch (e) {
    store.dispatch(notificationPriceFailLoadAllData(STRUCTURE_SWAP));
    console.log(e);
  }
}


const PRICING_SWAP_REQUEST_PRICE_MUTATION = gql`mutation fxRequestPriceMutation(
  $id: String!
) {
  pricingSwapRequestPrice (
    id: $id
  ) {
    id
    status,
  }
}`;

export const requestPriceMutation = ({
  id,
}) => {
  return apolloClient.mutate({
    mutation: PRICING_SWAP_REQUEST_PRICE_MUTATION,
    variables: {
      id,
    },
  }).then(response => response.data.pricingSwapRequestPrice);
}
