import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col, Row } from 'reactstrap';
import { mapNodes } from 'utils';
import AutoComplete from 'components/form/inputs/autocomplete/AutoComplete';
import AccountField from 'components/orders/accounts/AccountField';
import isFunction from 'lodash/isFunction';
import './clientFields.css';
import { CUSTOMER_OPTIONS_QUERY, LOCATION_OPTIONS_QUERY, USER_TRADERS_OPTIONS_QUERY } from './queries';

const clientQueryVariables = (_, search) => search ? { search } : null;
const withClientIdQueryVariables = ({ clientId, customerId } = {}, search) => {
  if(!!(!search && customerId)) {
    return {
      filter: [{ field: 'id', operator: 'eq', value: customerId }]
    }
  }
  return { clientId, search, first: 100 }
}
const locationsQueryVariables = ({ clientId } = {}, search) => clientId ? ({ clientId, search, first: 100 }) : null;

const filterVariable = value => value ? [{ field: 'id', operator: 'eq', value }] : null;

const optionsUserTradersList = (list = []) =>
  list.map(({ firstName, lastName, id: value }) => ({ label: `${firstName} ${lastName}`, value }));

const optionsList = (list = []) =>
  list.map(({ title: label, id: value }) => ({ label, value }));

const mapResponse = (respKey, isNodes = false, customOptionsList) => data => {
  const responseOptions = data[respKey] || [];
  const options = isNodes ? mapNodes(responseOptions) : responseOptions;
  return isFunction(customOptionsList) ? customOptionsList(options) : optionsList(options);
};

const mapCustomersResponse = mapResponse('userCustomers', true);
const mapLocationsResponse = mapResponse('userLocations', true);
const mapUserTradersResponse = mapResponse('userTraders', true, optionsUserTradersList);

const ClientFields = props => {
  const { isClient, isClientIdField, clientId = null, initialValues, isUserTradersField } = props;
  const [clientData, setClientData] = useState({ clientId, customerId: null });
  const [isFirstRequest, setFirstRequest] = useState(true);
  const [autoCompleteLoaded, setAutoCompleteLoaded] = useState({ customerId: true, locationId: true, traderId: true });

  const onInputChangeValue = ({ name, value }) => {
    if (clientData[name] !== value) {
      const values = { ...clientData, [name]: value };
      setClientData(values);
    }
  }

  const onAutocompleteLoaded = (options = [], { name }) => {
    if (Array.isArray(options) && !options?.length) {
      const values = { ...autoCompleteLoaded, [name]: false }
      setAutoCompleteLoaded(values);
    }
    if(isFirstRequest) setFirstRequest(false);
  }
  const { customerId } = clientData;
  return (
    isClient ?
      <Row className="client-fields">
        {isUserTradersField && autoCompleteLoaded?.traderId ?
          <Col xs={12} sm={12}>
            <Field
              name="traderId"
              label="User"
              placeHolder={'Please start typing the User'}
              queryVariables={clientQueryVariables}
              queryFilter={filterVariable(isFirstRequest && initialValues?.traderId)}
              query={USER_TRADERS_OPTIONS_QUERY}
              onAutocompleteLoaded={onAutocompleteLoaded}
              mapResponse={mapUserTradersResponse}
              component={AutoComplete}
              onInputChangeValue={onInputChangeValue}
              clearable={true}
              justValue={true}
              resetEmptyValue={false}
              selectedSingleOption={true}
              selectedActiveSingleOption={true}
            />
          </Col> : null}
        {isClientIdField ?
          <Col xs={12} sm={12}>
            <AccountField />
          </Col>
          : null}
        {autoCompleteLoaded?.customerId ?
          <Col xs={12} sm={12}>
            <Field
              name="customerId"
              label="Customer"
              placeHolder={'Please start typing the Customer name'}
              queryVariables={withClientIdQueryVariables}
              queryFilter={filterVariable(isFirstRequest && initialValues?.customerId)}
              query={CUSTOMER_OPTIONS_QUERY}
              onAutocompleteLoaded={onAutocompleteLoaded}
              mapResponse={mapCustomersResponse}
              component={AutoComplete}
              onInputChangeValue={onInputChangeValue}
              clearable={true}
              justValue={true}
              resetEmptyValue={false}
              formValues={{
                clientId: clientData?.clientId,
                customerId
              }}
              selectedSingleOption={true}
              selectedActiveSingleOption={true}
            />
          </Col>
          : null}
        {autoCompleteLoaded?.locationId ?
          <Col xs={12} sm={12}>
            <Field
              name="locationId"
              label="Location"
              placeHolder={'Please start typing the Location'}
              queryVariables={locationsQueryVariables}
              queryFilter={filterVariable(isFirstRequest && initialValues?.locationId)}
              query={LOCATION_OPTIONS_QUERY}
              onAutocompleteLoaded={onAutocompleteLoaded}
              mapResponse={mapLocationsResponse}
              component={AutoComplete}
              onInputChangeValue={onInputChangeValue}
              clearable={true}
              justValue={true}
              resetEmptyValue={false}
              formValues={{
                clientId: clientData?.clientId,
                locationId: clientData?.locationId
              }}
              selectedSingleOption={true}
              selectedActiveSingleOption={true}
            />
          </Col>
          : null}
      </Row>
      : null
  );
};

ClientFields.defaultProps = {
  isClient: false,
  isClientIdField: true,
  isUserTradersField: false,
};

ClientFields.propTypes = {
  isClient: PropTypes.bool.isRequired,
  isClientIdField: PropTypes.bool.isRequired,
  isUserTradersField: PropTypes.bool.isRequired,
};

export default memo(ClientFields);
