import Underlyings from "../../pages/structured-products/components/forms/underlyings/Underlyings";
import { InlineSelectInput } from "@agile/react-components";
import {
  ComponentOptions,
  Rule,
  WrapperData,
} from "@agile/common-types/build/configurableSp/types";
import { DecoratedFormProps } from "redux-form/lib/reduxForm";
import { isDefined } from "validate.js";
import { Dispatch, SetStateAction } from "react";
import Frequency from "../../pages/structured-products/components/forms/Frequency/Frequency";
import FirstObservationIn from "../../pages/structured-products/components/forms/FirstObservationIn/FirstObservationIn";
import FloatingCurve from "../../pages/structured-products/components/forms/FloatingCurve/FloatingCurve";

// key - defined in custom config in db
// legalShape is value mapped from resolve (graphql does not like hyphens)
export const CUSTOM_OPTIONS = {
  "legal-shapes": "legalShapes",
};

export const getCustomComponentsProps = (
  name: string,
  id: string,
  customOptionsMap: Record<string, ComponentOptions> = {},
  onChange: (evt, value: any) => void,
  formName: string,
  changeField: (formName: string, field: string, value: any) => void,
  validation: string | undefined,
  label: string | undefined,
) => {
  switch (name) {
    case "underlyings": {
      return {
        name: id,
        component: Underlyings,
        total: 1,
        showBottomPadding: false,
        addUnderlyingImmediately: true,
        formName,
        isForConfigurableSP: true,
        onUnderlyingChanged: onChange,
        validation,
        label,
      };
    }
    case "legal-shapes": {
      const key = CUSTOM_OPTIONS[name];

      return {
        name: id,
        component: InlineSelectInput,
        options: customOptionsMap[key],
        onChange,
        clearable: false,
      };
    }
    case "floatingCurve": {
      const optionsMap = customOptionsMap["floatingCurve"];
      return {
        name: id,
        component: FloatingCurve,
        optionsMap,
        formName,
        changeField,
        onChange,
      };
    }
    case "frequency": {
      return {
        name: id,
        component: Frequency,
        formName,
        changeField,
      };
    }
    case "firstObservationIn": {
      return {
        name: id,
        component: FirstObservationIn,
        formName,
        changeField,
      };
    }
    case "issuer": {
      if (!customOptionsMap[name]?.length) {
        return undefined;
      }
      const options = customOptionsMap[name];
      return {
        name: id,
        component: InlineSelectInput,
        options,
        onChange,
        clearable: false,
      };
    }
    default:
      // handle custom data from static service
      if (customOptionsMap[name]) {
        const options = customOptionsMap[name];
        return {
          name: id,
          component: InlineSelectInput,
          options,
          onChange,
          clearable: false,
        };
      }

      return undefined;
  }
};

interface UpdateValuesParams {
  formUpdate: any;
  fieldName: string;
  formValues: string;
  rules: Rule[];
  onChangeField: DecoratedFormProps["change"];
  setVisible: (visibleMap: Record<string, boolean>) => void;
  setReadOnly: (readOnlyMap: Record<string, boolean>) => void;
  activeRules: Rule[];
  setActiveRules: Dispatch<SetStateAction<UpdateValuesParams["activeRules"]>>;
}

export const updateValuesWithRules = ({
  formUpdate,
  formValues,
  fieldName,
  rules,
  onChangeField,
  setVisible,
  setReadOnly,
  activeRules,
  setActiveRules,
}: UpdateValuesParams) => {
  const visibleMap = {};
  const readOnlyMap = {};

  if (rules.length && onChangeField) {
    const formKeys = Object.keys(formValues);
    const underlyings = formKeys.filter(
      (key) => key.includes("underlying_") && isDefined(formValues[key]),
    );

    /**
     * Note: Rules are run in order, last rule will have priority
     */
    const rulesToRun: Rule[] = [];

    rules.forEach((rule) => {
      const { condition = [], update = [] } = rule;

      const hasMetAllConditions = condition.every(({ id, value, hasValue }) => {
        /**
         * special logic to handle reading underlyings custom field
         */
        const isUnderlyingField = id.includes("underlying_");
        const fieldValue = id === fieldName ? formUpdate : formValues[id];
        const formValue = fieldValue?.value || fieldValue;

        if (isUnderlyingField) {
          const index = parseInt(id.split("_")[1]) - 1;
          if (hasValue && isDefined(formValues[underlyings[index]])) {
            return true;
          }

          /**
           * generic logic to check rules
           */
        } else if (hasValue && isDefined(formValue)) {
          return true;
        } else if (isDefined(value) && value === formValue) {
          return true;
        }
        return false;
      });

      if (hasMetAllConditions) {
        rulesToRun.push(rule);
      }
    });

    /**
     * Need to revert readOnly and visible fields when conditions
     * no longer match
     */
    const rulesToBeReverted = activeRules.filter((r) => {
      return !rulesToRun.includes(r);
    });

    rulesToBeReverted.forEach(({ update }) => {
      update.forEach((u) => {
        if (isDefined(u.visible)) {
          visibleMap[u.id] = !u.visible!;
        }
        if (isDefined(u.readOnly)) {
          readOnlyMap[u.id] = !u.readOnly!;
        }
      });
    });

    rulesToRun.forEach(({ update }) => {
      update.forEach((u) => {
        if (isDefined(u.value)) {
          onChangeField(u.id, u.value);
        }
        if (isDefined(u.visible)) {
          visibleMap[u.id] = u.visible;
        }
        if (isDefined(u.readOnly)) {
          readOnlyMap[u.id] = u.readOnly;
        }
      });
    });

    setActiveRules(rulesToRun);
  }

  setVisible(visibleMap);
  setReadOnly(readOnlyMap);
};

export const getWrapperLayout = (
  wrapperList: WrapperData[] | undefined,
  selectedWrapper: string | undefined,
) =>
    wrapperList?.find((wrapper) => wrapper.wrapperUri === selectedWrapper)
    ?.layout || [];

export const shouldUpdate = (prev, props) => {
  // reduxform has a bug (infinite loop)  where it will re-render too many times
  return JSON.stringify(prev) === JSON.stringify(props);
};
