export const NOTIFICATION_TYPE_SYSTEM = 'SYSTEM';
export const NOTIFICATION_TYPE_DEFAULT = 'MESSAGE';

// Action types
export const NOTIFICATION_ACTION_TYPE_CONTRACT = 'CONTRACT';
export const NOTIFICATION_ACTION_TYPE_ORDER = 'ORDER';
export const NOTIFICATION_ACTION_TYPE_COMMON_ORDER = 'COMMONORDER';
export const NOTIFICATION_ACTION_TYPE_OPEN_TRADES = 'OPEN_TRADES';
export const NOTIFICATION_ACTION_TYPE_DEFAULT = 'Notification';
export const NOTIFICATION_ACTION_TYPE_CARD = 'CARD';
export const NOTIFICATION_ACTION_TYPE_MINIFUTURE = 'MINIFUTURE';
export const NOTIFICATION_ACTION_TYPE_CALENDAR_SPREAD = 'CALENDAR_SPREAD';
export const NOTIFICATION_ACTION_TYPE_MF_FX = 'MF_FX';
export const NOTIFICATION_ACTION_REFERENCE_PRICE = 'REFERENCE_PRICE';

// Action events
export const NOTIFICATION_ACTION_READ = 'READ';
export const NOTIFICATION_ACTION_UPDATE = 'UPDATE';
export const NOTIFICATION_ACTION_CREATE = 'CREATE';
