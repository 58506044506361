import { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { LIMIT_PRICE_LABEL, PRICE_LABEL, TYPE_MARKET, TYPE_STOP, TYPE_STOP_LIMIT, TYPE_LIMIT } from '../constants.js';
import InlineInput from 'components/form/InlineInput';

const PriceField = ({ orderType, isCombinedCard, formatMessage, compoPrice, name = 'price' }) => {

  const [price, setPrice] = useState(compoPrice);

  useEffect(() => {
    if(compoPrice) setPrice(compoPrice);
  }, [compoPrice]);

  if (isCombinedCard && orderType !== TYPE_LIMIT) {
    return (
      <InlineInput
        label={<FormattedMessage id="trade/price.label"
          defaultMessage="Price" />}
        placeHolder={formatMessage({
          id: "trade/price.label",
          defaultMessage: "Price",
        })}
        disabled={true}
        type="number"
        step="1"
        meta={{}}
        input={{
          value: price,
          name,
        }}
      />
    );
  }

  if (orderType === TYPE_MARKET || orderType === TYPE_STOP) {
    return (
      <Field
        className="text-center"
        name="priceMkt"
        type="text"
        disabled={true}
        label={<FormattedMessage id="trade/price.label"
          defaultMessage="Price" />}
        step="1"
        component={InlineInput}
      />
    );
  }

  const label = TYPE_STOP_LIMIT === orderType ? LIMIT_PRICE_LABEL : PRICE_LABEL;
  return (<Field
    name={name}
    type="number"
    placeHolder={formatMessage({
      id: "trade/price.label",
      defaultMessage: label,
    })}
    label={<FormattedMessage id="trade/price.label"
      defaultMessage={label}/>}
    step="1"
    component={InlineInput}
  />);
};

PriceField.defaultProps = {
  name: 'price'
}

PriceField.propTypes = {
  name: PropTypes.string.isRequired,
}


export default PriceField;
