import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import PrintModalHeader from './PrintModalHeader';
import { toggleOpen } from 'components/layout/header/ProfileDropdown';
import './PrintModal.css';

// @TODO: implenent download for PDF file via BE
class PrintModal extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      isOpen: true
    };
    this.print = this._print.bind(this);
    this.toggleModal = toggleOpen.bind(this);
  }

  _print() {
    window.print();
  }

  render() {
    const { isOpen } = this.state;
    const { className, onClose, children } = this.props;
    return (
      <Fragment>
        <PrintModalHeader onClose={this.toggleModal} onPrint={this.print} />
        <Modal
          isOpen={isOpen}
          toggle={this.toggleModal}
          className={'print-modal ' + (className ? className : '')}
          backdrop="static"
          backdropClassName="print-modal-backdrop"
          onClosed={onClose}
        >
          <ModalBody>
            {children}
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

PrintModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

PrintModal.defaultProps = {
  isOpen: false,
}

export default PrintModal;
