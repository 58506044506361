import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { themeImagesSelector } from "components/theme/utils";
import {
  ROUTE_BOOKING,
  ROUTE_MY_DASHBOARD,
  ROUTE_HOME,
  ROUTE_PORTAL,
  ROUTE_MINIFUTURE,
  ROUTE_PRICING,
  ROUTE_PRICING_MINI,
  ROUTE_PROFILE,
  ROUTE_STRUCTURED_PRODUCTS,
  ROUTE_TRADES_HISTORY,
  ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS,
} from "pages/constants";
import {
  ROLE_BOOKING,
  ROLE_DASHBOARD,
  ROLE_PORTAL,
  ROLE_MINIFUTURE,
  ROLE_PRICING,
  ROLE_PRICING_MINI,
  ROLE_STRUCTURED_PRODUCTS,
  ROLE_TRADES_HISTORY,
  ROLE_MY_DASHBOARD,
  ROLE_CONFIGURABLE_SP,
} from "pages/roles";
import { allowedStates } from "pages/routes/PrivateRouter";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { withApollo } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { NavLink as Link, withRouter } from "react-router-dom";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";
import { bindActionCreators, compose } from "redux";
import { logout } from "redux/actions/auth";
import { createSelector } from "reselect";
import NotifyMeSubscription from "../../notifyMe/NotifyMeSubscription";
import { withBreakPointsContext } from "../../theme/breakPoints/BreakPoints";
import {
  BREAKPOINT_XS,
  isMobileScreen,
} from "../../theme/breakPoints/constants";
import NavDashboards from "../header/NavDashboards";
import ProfileDropdown from "../header/ProfileDropdown";
import "../nonLogged/NonLoggedLayout.css";
import { isActiveLink } from "./utils";

class LoggedHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
    };
    this.isActiveLink = isActiveLink.bind(this);
  }

  toogleNav = () => {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  };

  logout = () => {
    const { client, logout } = this.props;
    client.cache.reset();
    logout();
  };

  render() {
    const {
      allowed,
      dashboards,
      responsive: { screenSize },
      images = {},
    } = this.props;
    const { isNavOpen } = this.state;
    const isAllowedPricing =
      allowed && allowed.includes(ROLE_PRICING) ? true : false;
    const isMinifutures =
      allowed && allowed.includes(ROLE_MINIFUTURE) ? true : false;
    const isTradesHistory =
      allowed && allowed.includes(ROLE_TRADES_HISTORY) ? true : false;
    const isAllowedDashboard =
      allowed && allowed.includes(ROLE_DASHBOARD) ? true : false;
    const isPortal = allowed && allowed.includes(ROLE_PORTAL) ? true : false;
    const isPricingMini =
      allowed && allowed.includes(ROLE_PRICING_MINI) ? true : false;
    const isAllowedStructuredProducts =
      allowed && allowed.includes(ROLE_STRUCTURED_PRODUCTS) ? true : false;
    const isMyDashboards =
      allowed && allowed.includes(ROLE_MY_DASHBOARD) ? true : false;
    const isBooking = allowed && allowed.includes(ROLE_BOOKING) ? true : false;
    const isConfigurableSp =
      allowed && allowed.includes(ROLE_CONFIGURABLE_SP) ? true : false;

    return (
      <Fragment>
        <Container fluid={true} className="logged-header fixed-top">
          <Navbar light expand="lg">
            <NavbarBrand
              tag={Link}
              className="align-items-center d-flex"
              to={ROUTE_HOME}
            >
              <img
                src={images?.logo}
                alt="Logo Marex Solutions"
                className="img-fluid logo"
              />
            </NavbarBrand>
            <Collapse isOpen={isNavOpen} navbar>
              <Nav navbar className="page-nav">
                {isAllowedDashboard ? (
                  <NavDashboards list={dashboards || []} />
                ) : null}
                {isMyDashboards ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_MY_DASHBOARD)}`}
                      to={ROUTE_MY_DASHBOARD}
                    >
                      <FormattedMessage
                        id="header.mydashboard.label"
                        defaultMessage="My Dashboard"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isAllowedPricing ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_PRICING)}`}
                      to={ROUTE_PRICING}
                    >
                      <FormattedMessage
                        id="header.price.label"
                        defaultMessage="Pricing Pro"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isAllowedStructuredProducts ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_STRUCTURED_PRODUCTS)}`}
                      to={ROUTE_STRUCTURED_PRODUCTS}
                    >
                      <FormattedMessage
                        id="header.structured-products.label"
                        defaultMessage="Structured Products"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}

                {isPricingMini ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_PRICING_MINI)}`}
                      to={ROUTE_PRICING_MINI}
                    >
                      <FormattedMessage
                        id="header.pricing-mini.label"
                        defaultMessage="Pricing Mini"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isTradesHistory ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder tour-page-trades-history${this.isActiveLink(ROUTE_TRADES_HISTORY)}`}
                      to={ROUTE_TRADES_HISTORY}
                    >
                      <FormattedMessage
                        id="header.trades-history.label"
                        defaultMessage="Trade History"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isPortal ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder tour-page-trades-history${this.isActiveLink(ROUTE_PORTAL)}`}
                      to={ROUTE_PORTAL}
                    >
                      <FormattedMessage
                        id="header.portal.title"
                        defaultMessage="Portal"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isMinifutures ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_MINIFUTURE)}`}
                      to={ROUTE_MINIFUTURE}
                    >
                      <FormattedMessage
                        id="header.minifuture.label"
                        defaultMessage="Minifutures"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isBooking ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_BOOKING)}`}
                      to={ROUTE_BOOKING}
                    >
                      <FormattedMessage
                        id="header.booking.label"
                        defaultMessage="Booking"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}
                {isConfigurableSp ? (
                  <NavItem>
                    <NavLink
                      exact
                      tag={Link}
                      activeClassName={null}
                      className={`text-uppercase text-bolder${this.isActiveLink(ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS)}`}
                      to={ROUTE_EXPERIMENTAL_STRUCTURED_PRODUCTS}
                    >
                      <FormattedMessage
                        id="header.configurableSp.label"
                        defaultMessage="SP Preview"
                      />
                    </NavLink>
                  </NavItem>
                ) : null}

                {screenSize === BREAKPOINT_XS ? (
                  <Fragment>
                    <NavLink
                      exact
                      tag={Link}
                      to={ROUTE_PROFILE}
                      className="text-uppercase text-bolder"
                    >
                      <FormattedMessage
                        id="logged.header.user-menu.profile"
                        defaultMessage="Profile"
                      />
                    </NavLink>
                    <NavLink
                      onClick={this.logout}
                      className="text-uppercase text-bolder"
                    >
                      <FormattedMessage
                        id="logged.header.user-menu.log-out-message"
                        defaultMessage="Log out"
                      />
                    </NavLink>
                  </Fragment>
                ) : null}
              </Nav>
            </Collapse>

            <div className="flex-row ml-auto header-profile navbar-nav">
              <NotifyMeSubscription isVisible={isAllowedPricing} />
              {screenSize !== BREAKPOINT_XS ? (
                <ProfileDropdown {...this.props} />
              ) : null}
            </div>

            <div className="header-menu d-lg-none">
              <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={this.toogleNav}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Navbar>
        </Container>
        {isMobileScreen(screenSize) && isNavOpen ? (
          <div
            className="bg-overlay bg-header-overlay"
            onClick={this.toogleNav}
          ></div>
        ) : null}
      </Fragment>
    );
  }
}

LoggedHeader.defaultProps = {
  images: {},
};

LoggedHeader.propTypes = {
  allowed: PropTypes.array.isRequired,
  dashboards: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  images: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export const selectorAccount = createSelector(
  ({ auth }) => auth,
  ({ account }) => account,
);

const userDataSelctor = createSelector(selectorAccount, (account) => ({
  allowed: account ? allowedStates(account.roles) : null,
  dashboards: account ? account.dashboards : [],
}));

const mapStateToProps = (state) => ({
  ...userDataSelctor(state),
  images: themeImagesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
  withBreakPointsContext,
)(LoggedHeader);
