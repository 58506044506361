import Modal from 'components/modal/Modal';
import ModalMessage from 'components/modal/ModalMessage';
import { renderSuccessMessage } from 'components/successMessage/SuccessMessage';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

const Footer = ({ onClose, onAccept, isSubmitting }) => (
  <div className="text-center">
    <Button color="default" type="button" className="mr-3" onClick={onClose}>
      <FormattedMessage id="common/cancel" defaultMessage="No" />
    </Button>
    <Button type="button" color="primary" onClick={onAccept} disabled={isSubmitting}>
      <FormattedMessage id="common/send-order" defaultMessage="Yes" />
    </Button>
  </div>
);

const OtcOrderCancelModal = props => {
  const { handleCancelationOrder } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  const onCloseModal = () => setIsOpen(false);

  const openModal = () => {
    setIsOpen(true);
    setIsSubmitted(false);
    setIsSubmitting(false);
  }

  const onAcceptModal = async () => {
    setIsSubmitting(true);
    const result = await handleCancelationOrder();
    setIsSubmitting(false);
    const msg = result.msg ? ': ' + result.msg : '';
    setUserMessage(result.isSuccess ? 'Order was cancelled' : 'Failed to cancel message' + msg)
    onCloseModal();
    setIsSubmitted(true);
  }

  return (
    <Fragment>
      <Button size="sm" onClick={openModal} >
        <FormattedMessage id="otc-order/cancel" defaultMessage="Cancel" />
      </Button>
      <Modal
        btnType="primary"
        btnClass="btn-icon"
        isOpen={isOpen}
        onToggle={onCloseModal}
        onClose={onCloseModal}
        component={renderSuccessMessage('Are you sure you want to cancel this order?')}
        footer={<Footer onClose={onCloseModal} onAccept={onAcceptModal} isSubmitting={isSubmitting} />}
      />
      {isSubmitted ?
        <ModalMessage>
          {renderSuccessMessage(userMessage)}
        </ModalMessage >
        :
        null
      }
    </Fragment>
  );
}

export default OtcOrderCancelModal;
