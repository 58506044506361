import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { PRICING_STRUCTURED_FORM_SHEDULE } from 'pages/structured-products/components/forms/constants';
import { structuredProductsScheduleUserInputs } from 'redux/actions/structured-products';
import { REDUX_FORM_SUBMITTED } from 'redux/epics/constants';
import { filter, switchMap, map } from 'rxjs/operators';
import { scheduleFormDataSelector, scheduleGetFormInitialValues } from '.';

const structuredFormShceduleEpic = action => action.type === REDUX_FORM_SUBMITTED && action.meta.form === PRICING_STRUCTURED_FORM_SHEDULE;

const parseScheduleUserInputs = (values, initialValues) => {
  let results = {};
  Object.keys(values)
    .filter((fieldName) => fieldName?.indexOf('-') >= 0)
    .forEach((fieldName) => {
      const value = values[fieldName];
      const initialValue = initialValues[fieldName];
      if (value !== initialValue) {
        const [name, index] = fieldName.split('-');
        if (!results[index]) results[index] = {};
        results[index][name] = value;
      }
    });
  return !isEmpty(results) ? results : null;
};

const submittedFormEpic = (action$, state$) =>
  action$
    .pipe(
      filter(structuredFormShceduleEpic),
      map(() => {
        const values = scheduleFormDataSelector(state$.value);
        const initialValues = scheduleGetFormInitialValues(state$.value);
        const userInputs = parseScheduleUserInputs(values, initialValues);

        return userInputs;
      }),
      filter((userInputs) => !isEmpty(userInputs)),
      switchMap((userInputs) => [structuredProductsScheduleUserInputs(userInputs)])
    );

export default submittedFormEpic;
