import { create, all } from 'mathjs';
import { STRUCTURE_AUTOCALLABLE, STRUCTURE_CAPITAL_PROTECTION, STRUCTURE_TWIN_WIN, AUTOCALL_VALUE, STRUCTURE_FIXED_COUPON_NOTE } from 'constants.js';
import round from 'lodash/round';
import { PRICING_STRUCTURED_FORM } from 'pages/structured-products/components/forms/constants';
import { formValueSelectorStructuredForm, formValuesSelectorStructuredForm } from 'pages/structured-products/components/forms/PriceForm';
import { getIssuerMaxCouponLevel } from 'pages/structured-products/components/forms/types/autocallable/AutocallableFormValidate';
import AUTOCALLABLE_FORM_FIELDS from 'pages/structured-products/components/forms/types/autocallable/fields';
import CAPITAL_PROTECTION_FORM_FIELDS from 'pages/structured-products/components/forms/types/capitalProtection/fields';
import TWIN_WIN_FORM_FIELDS from 'pages/structured-products/components/forms/types/twinWin/fields';
import { blur, change, touch, unregisterField, untouch } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { AUTH_LOGOUT } from 'redux/actions/auth';
import { setScheduleValidity, loadStructuredProductsFormDataLoaded, setStructuredProductsLoader, structuredProductsFieldsSet, structuredProductsFieldUpdate, structuredProductsFormConfigSet, structuredProductsFormOptionsSet, structuredProductsStructureSet, structuredProductsScheduleLoader, restoreStructuredHistoryFinishAction, restoreStructuredFormUnderlyingsCreateListAction } from 'redux/actions/structured-products';
import { notificationErrorSimple } from 'redux/alerts/actions';
import { distinctObjectChanges } from 'redux/epics/utils';
import { getStructuredProductsFormData } from 'redux/queries/structured-products/';
import { from, merge } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { changeActionFilter, changeActionFilterArray, changeFormActionFilter, destroyReduxFieldFilter, registerFieldsActionFilter } from 'utils/reduxFormSelector';
import { priceFormDestroyFilter } from '../../price/filters';
import { AMERICAN_KNOCK_OUT, EUROPEAN_KNOCK_OUT } from './constants';
import { addDisabledOptions, removeDisabledOptions } from './options/handleOptions';
import { caseStrucutredProductsFormConfig, structuredProductsFormResetFilter, structuredProductsFormStrucutreFilter, structureRestoringFromHistoryFilter } from './utils';
import { handleVisibleFormFields } from './visibleFields';
import { PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES } from 'pages/structured-products/components/forms/types/autocallable/constats.js';
import FIXED_COUPON_NOTE_FORM_FIELDS from 'pages/structured-products/components/forms/types/fixedCouponNote/fields';

const mathjs = create(all);
mathjs.config({
  number: 'BigNumber', // Default type of number:
  precision: 64 // Number of significant digits for BigNumbers
});

export const pricingStructuredFormValuesFilter = state => state.form && state.form[PRICING_STRUCTURED_FORM] && state.form[PRICING_STRUCTURED_FORM].values;

const resetFormDefaultValuesActions = (structure, fieldValues = {}, config = {}, skipNullable = []) => {
  let actions = [];
  const { initialValues } = caseStrucutredProductsFormConfig(structure);
  const fieldNames = Object.keys(fieldValues);
  if (fieldNames && fieldNames.length) {
    fieldNames.forEach((fieldKey) => {
      if (fieldValues[fieldKey] === false) {
        if(!skipNullable.includes(fieldKey)) actions.push(change(PRICING_STRUCTURED_FORM, fieldKey, null));
      } else if (
          fieldValues[fieldKey] !== config[fieldKey]
          && fieldValues[fieldKey] !== initialValues[fieldKey]
        ) {
          actions.push(change(PRICING_STRUCTURED_FORM, fieldKey, initialValues[fieldKey]));
        }
    });
  }
  return actions;
};

const changesDisabledValue = (options, action, barrierTypeValue, barrierType2Value) => [
  structuredProductsFormOptionsSet({ barrierType: options }),
  ...['barrierType', 'barrierType2'].map(field => action(PRICING_STRUCTURED_FORM, field)),
  change(PRICING_STRUCTURED_FORM, 'barrierType', barrierTypeValue),
  change(PRICING_STRUCTURED_FORM, 'barrierType2', barrierType2Value),
];

const additionalChanges = (basketType, capType, capType2, barrierTypeOptions, barrierType, barrierType2) => {
  if (basketType?.toLowerCase() === 'basket' && (capType?.toLowerCase() === 'barrier' || capType2?.toLowerCase() === 'barrier')) {
    const options = addDisabledOptions(barrierTypeOptions, AMERICAN_KNOCK_OUT);
    return changesDisabledValue(options, untouch, EUROPEAN_KNOCK_OUT, EUROPEAN_KNOCK_OUT);
  } else {
    const options = removeDisabledOptions(barrierTypeOptions);
    return changesDisabledValue(options, touch, barrierType, barrierType2);
  }
};

export const structuredFormChangesEpic = (action$, state$) =>
  action$
    .pipe(
      filter(changeFormActionFilter(PRICING_STRUCTURED_FORM)),
      distinctUntilChanged(),
      filter(() => pricingStructuredFormValuesFilter(state$.value)),
      debounceTime(200),
      map(() => formValuesSelectorStructuredForm(state$.value)),
      map((formData) => [handleVisibleFormFields(formData), formData.structure]),
      distinctUntilChanged(distinctObjectChanges),
      switchMap(([fieldValues, structure]) => {
        const structuredProductsFormConfig = state$.value.structuredProducts.formConfig;
        const { autocall } = formValuesSelectorStructuredForm(state$.value);
        const skipNullable = autocall === AUTOCALL_VALUE.ISSUER_CALLABLE ? ['stepDown', 'autocallTriggerLevel', 'step']: []

        // Handle change options and set default values after show/hide fields
        const actions = [
          structuredProductsFormConfigSet(fieldValues),
          ...resetFormDefaultValuesActions(structure, fieldValues, structuredProductsFormConfig, skipNullable),
        ];

        return from(actions)
      }),
    );

export const changeAutocallableStrikeLevelEpic = (action$, state$) =>
  action$.pipe(
    filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'strikeLevel')),
    filter(({ payload }) => parseFloat(payload)),
    debounceTime(100),
    map(({ payload }) => payload),
    distinctUntilChanged(),
    switchMap((strikeLevel) => {
      const downsideLeverageValue = round(10000 / parseFloat(strikeLevel), 2);
      const actions = [
        change(PRICING_STRUCTURED_FORM, 'downsideLeverage', downsideLeverageValue)
      ];

      return from(actions)
    })
  );

export const changeСouponTriggerLevelValueEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'autocallTriggerLevel')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
      filter(payload => parseFloat(payload) && formValueSelectorStructuredForm(state$.value, 'snowball') === true),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'snowball')),
      map(({ payload }) => payload),
      distinctUntilChanged(),
      filter(payload => payload === true),
    )
  )
    .pipe(
      switchMap(() => {
        const autocallTriggerLevel = formValueSelectorStructuredForm(state$.value, 'autocallTriggerLevel');
        const couponTriggerLevelValue = parseFloat(autocallTriggerLevel);
        const actions = [
          change(PRICING_STRUCTURED_FORM, 'couponTriggerLevel', couponTriggerLevelValue)
        ];

        return from(actions)
      })
    );

export const destroyReduxFieldEpic = action$ =>
  action$.pipe(
    filter(destroyReduxFieldFilter(PRICING_STRUCTURED_FORM)),
    filter(pricingStructuredFormValuesFilter),
    filter(({ meta, payload }) => meta && payload),
    map(({ meta: { form }, payload: { name } }) => unregisterField(form, name)),
    // map(({ meta: { form }, payload: { name } }) => change(form, name, null)),
  );

export const changeСouponStepDownEpic = action$ =>
  action$.pipe(
    filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'stepDown')),
    map(({ payload }) => payload),
    distinctUntilChanged(),
    debounceTime(100),
    switchMap((stepDown) => {
      const label = stepDown ? 'First Autocall Trigger' : 'Autocall Trigger Level';
      const options = {
        label,
        placeHolder: `Enter ${label}`,
      }
      return from([
        structuredProductsFieldUpdate({ name: 'autocallTriggerLevel', options })
      ])
    })
  );

const couponLevelMaxActions = (issuer, issuerOptions = []) => {
  const max = getIssuerMaxCouponLevel(issuerOptions, issuer || issuerOptions[0]?.value);
  const options = { max };
  return [
    structuredProductsFieldUpdate({ name: 'couponLevel', options }),
    blur(PRICING_STRUCTURED_FORM, 'couponLevel'),
  ];
};

export const changeIssuerEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(structuredProductsFormResetFilter),
      map(() => {
        const { issuer } = formValuesSelectorStructuredForm(state$.value) || {};
        return issuer;
      })
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'issuer')),
      map(({ payload }) => payload),
    ),
  )
    .pipe(
      debounceTime(100),
      filter(() => state$.value.structuredProducts?.formOptions?.issuer),
      switchMap((issuer) => {
        const issuerOptions = state$.value.structuredProducts?.formOptions?.issuer;
        const actions = couponLevelMaxActions(issuer, issuerOptions);
        return from(actions);
      })
    );

const caseFormFieldsActions = structure => {
  switch (structure) {
    case STRUCTURE_AUTOCALLABLE:
      return [
        structuredProductsStructureSet(STRUCTURE_AUTOCALLABLE),
        structuredProductsFieldsSet(AUTOCALLABLE_FORM_FIELDS),
      ];
    case STRUCTURE_TWIN_WIN:
      return [
        structuredProductsStructureSet(STRUCTURE_TWIN_WIN),
        structuredProductsFieldsSet(TWIN_WIN_FORM_FIELDS),
      ];
    case STRUCTURE_CAPITAL_PROTECTION:
      return [
        // @TODO: add schedule reset and remove all data
        structuredProductsStructureSet(STRUCTURE_CAPITAL_PROTECTION),
        structuredProductsFieldsSet(CAPITAL_PROTECTION_FORM_FIELDS),
      ];
    case STRUCTURE_FIXED_COUPON_NOTE:
      return [
        structuredProductsStructureSet(STRUCTURE_FIXED_COUPON_NOTE),
        structuredProductsFieldsSet(FIXED_COUPON_NOTE_FORM_FIELDS),
      ];
    default:
      return [];
  }
};

export const changeStructureEpic = (action$, state$) =>
  action$.pipe(
    filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'structure')),
    filter(({ payload }) => payload),
    map(({ payload }) => payload),
    switchMap(structure => {
      return from(getStructuredProductsFormData(structure))
        .pipe(
          map(data => ({ ...data, structure })),
          catchError(() => from([notificationErrorSimple(`Can't fetch options`)])),
          takeUntil(action$.pipe(filter(
            action => action.type === AUTH_LOGOUT ||
              priceFormDestroyFilter(action)
          )))
        );
    }),
    switchMap(data => {
      const formData = formValuesSelectorStructuredForm(state$.value);
      const visibleFields = handleVisibleFormFields(formData);
      const { structure, ...response } = data;
      const actions = [
        setScheduleValidity(false),
        structuredProductsFormConfigSet(visibleFields),
        loadStructuredProductsFormDataLoaded({ data: response, isFirstRequest: structureRestoringFromHistoryFilter(state$) }),
        ...caseFormFieldsActions(structure),
        restoreStructuredHistoryFinishAction(),
        setStructuredProductsLoader(false),
      ]
      return from(actions);
    })
  );

export const changeBasketTypeValueEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'basketType')),
      map(({ payload }) => payload),
      filter(payload => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'capType')),
      map(({ payload }) => payload),
      filter(payload => payload),
      distinctUntilChanged(),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'capType2')),
      map(({ payload }) => payload),
      filter(payload => payload),
      distinctUntilChanged(),
    ),
  )
    .pipe(
      filter(() => structureRestoringFromHistoryFilter(state$)),
      debounceTime(250),
      switchMap(() => {
        const formData = formValuesSelectorStructuredForm(state$.value);
        const {
          capType,
          capType2,
          basketType,
          barrierType,
          barrierType2,
        } = formData;
        const barrierTypeOptions = state$.value.structuredProducts.formOptionsDefault.barrierType;

        const actions = additionalChanges(basketType, capType, capType2, barrierTypeOptions, barrierType, barrierType2);

        return from(actions);
      })
    );

const eventOptionTypeAndCapTypeUpdate = (action$, state$, optionType, fieldsTriggerChange = [], fields = [], debounce = 200) => merge(
  action$.pipe(
    filter(changeActionFilter(PRICING_STRUCTURED_FORM, optionType)),
    map(({ payload }) => payload),
    distinctUntilChanged(),
    filter(payload => payload),
  ),
  action$.pipe(
    filter(changeActionFilterArray(PRICING_STRUCTURED_FORM, fieldsTriggerChange)),
  ),
  action$.pipe(
    filter(registerFieldsActionFilter(PRICING_STRUCTURED_FORM, fields)),
  )
).pipe(
  filter(() => structureRestoringFromHistoryFilter(state$)),
  filter(() => structuredProductsFormStrucutreFilter(state$, STRUCTURE_CAPITAL_PROTECTION)),
  debounceTime(debounce),
  switchMap(() => {
    const formData = formValuesSelectorStructuredForm(state$.value);
    const optionTypeValue = formData[optionType]?.toLowerCase() === 'call' ? 150 : 50;
    const actions = fields.map(field => change(PRICING_STRUCTURED_FORM, field, optionTypeValue));
    return from(actions);
  })
);

export const changeOptionTypeAndCapTypeValueEpic2 = (action$, state$) =>
  eventOptionTypeAndCapTypeUpdate(action$, state$, 'optionType2', ['capType2'], ['capLevel2', 'barrierLevel2']);

export const changeOptionTypeAndCapTypeValueEpic = (action$, state$) =>
  eventOptionTypeAndCapTypeUpdate(action$, state$, 'optionType', ['capType'], ['capLevel', 'barrierLevel']);

const getStep = (state) => {
  const { barrierType, maturity, frequency, strikeLevel, barrierLevel } = formValuesSelectorStructuredForm(state);
  const { structuredProducts: { formOptions } } = state;
  const { issuerCallableDistance = 0, issuerCallableTrigger = 0 } = formOptions;

  if(!issuerCallableDistance || !issuerCallableTrigger) return PRICING_FORM_AUTOCALLABLE_INITIAL_VALUES.step;

  const NbObservations = Math.floor(maturity/frequency);
  const n = issuerCallableTrigger - (barrierType === 'None' ? strikeLevel : barrierLevel);
  const div = mathjs.divide(mathjs.bignumber(n), mathjs.bignumber(NbObservations));
  return mathjs.multiply(div, mathjs.bignumber(issuerCallableDistance)).toNumber()
}

const triggerLoading = (action$, state$) =>
  action$.pipe(
    filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'autocall')),
    map(({ payload }) => payload),
    filter(payload => payload),
    switchMap(() => {
      const actions = [];
      const { structuredProducts: { schedule } } = state$.value;
      actions.push(
        structuredProductsScheduleLoader(!!schedule),
      )
      return from(actions)
    })
  )

const changeAutocallEpic = (action$, state$) =>
  action$.pipe(
    filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'autocall')),
    map(({ payload }) => payload),
    filter(payload => payload),
    switchMap((autocall) => {
      const actions = [];
      if(autocall === AUTOCALL_VALUE.ISSUER_CALLABLE) {
        const { structuredProducts: { formOptions } } = state$.value;
        const { issuerCallableTrigger = 0 } = formOptions;

        const step = getStep(state$.value)
        actions.push(
          change(PRICING_STRUCTURED_FORM, 'stepDown', true),
          change(PRICING_STRUCTURED_FORM, 'step', step),
          change(PRICING_STRUCTURED_FORM, 'autocallTriggerLevel', issuerCallableTrigger || 0),
        )
      } else {
        const { stepDown, step, autocallTriggerLevel } = formValuesSelectorStructuredForm(state$.value);
        actions.push(
          change(PRICING_STRUCTURED_FORM, 'stepDown', stepDown),
          change(PRICING_STRUCTURED_FORM, 'step', step),
          change(PRICING_STRUCTURED_FORM, 'autocallTriggerLevel', autocallTriggerLevel),
        )
      }
      return from(actions)
    })
  )

const changeStepAutocallEpic = (action$, state$) =>
  merge(
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'barrierType')),
      map(({ payload }) => payload),
      filter(payload => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'maturity')),
      map(({ payload }) => payload),
      filter(payload => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'frequency')),
      map(({ payload }) => payload),
      filter(payload => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'strikeLevel')),
      map(({ payload }) => payload),
      filter(payload => payload),
    ),
    action$.pipe(
      filter(changeActionFilter(PRICING_STRUCTURED_FORM, 'barrierLevel')),
      map(({ payload }) => payload),
      filter(payload => payload),
    ),
  ).pipe(
    filter(() => {
      const { autocall } = formValuesSelectorStructuredForm(state$.value);
      return autocall === AUTOCALL_VALUE.ISSUER_CALLABLE
    }),
    debounceTime(250),
    switchMap(() => {
      const step = getStep(state$.value);
      return from([
        change(PRICING_STRUCTURED_FORM, 'step', step),
      ]);
    })
  );

export default combineEpics(
  structuredFormChangesEpic,
  changeAutocallableStrikeLevelEpic,
  changeСouponTriggerLevelValueEpic,
  changeСouponStepDownEpic,
  destroyReduxFieldEpic,
  changeStructureEpic,
  changeBasketTypeValueEpic,
  changeOptionTypeAndCapTypeValueEpic2,
  changeOptionTypeAndCapTypeValueEpic,
  changeIssuerEpic,
  triggerLoading,
  changeAutocallEpic,
  changeStepAutocallEpic,
);
