import Grid from "components/grid/Grid";
import React, { ComponentProps } from "react";

interface PreviewSpProductHistoryProps {
  items: ComponentProps<typeof Grid>["rowData"];
}

const createValueArrayFormatter =
  (field: string) => (data?: { value: { [field: string]: string } }) => {
    if (data?.value) {
      if (Array.isArray(data.value)) {
        return data.value.map((underlying) => underlying[field]).join("");
      }
      return data.value[field];
    }
  };

const FIELDS_FORMATTER_MAP = {
  underlyings: createValueArrayFormatter("title"),
  productAnalysis: createValueArrayFormatter("bloombergTicker"),
};

const getColumnDefs = (items: PreviewSpProductHistoryProps["items"]) => {
  return Object.keys(items[0]).map((key) => ({
    field: key,
    valueFormatter: FIELDS_FORMATTER_MAP[key] ?? undefined,
  }));
};

const PreviewSpProductHistory: React.FC<PreviewSpProductHistoryProps> = ({
  items,
}) => {
  return (
    <div className="preview-sp-product-history">
      <Grid rowData={items} columnDefs={getColumnDefs(items)} />
    </div>
  );
};

export default PreviewSpProductHistory;
