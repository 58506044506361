import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Progress } from 'reactstrap';
import './countdown.css';

const SecondsFormatted = ({ seconds }) => (
  <div className="countdown-second">
    {seconds}
  </div>
);

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const timer = setInterval(tick, delay);
    function tick() {
      savedCallback.current(timer);
    }
    return () => clearInterval(timer);
  }, [delay]);
}

const AnimatedSeconds = ({ seconds }) => (
  seconds < 10 ?
    <CSSTransition key={seconds} classNames="transition-appear-scale" timeout={180} in appear={true}>
      <SecondsFormatted seconds={seconds} />
    </CSSTransition>
    :
    <SecondsFormatted seconds={seconds} />
);

const CountDown = props => {
  const { count = 0, showProgress, showCounter, text, className, onStop, id } = props;
  const [seconds, setSeconds] = useState(count);

  useInterval((timer) => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    } else {
      if(onStop) onStop(id);
      clearInterval(timer)
    }
  }, 1000)

  return (
    seconds >= 0 ?
      <div className={className}>
        {showCounter ? <AnimatedSeconds seconds={seconds} /> : null}
        {showProgress ? <Progress value={100 - ((count - seconds) / count) * 100} color={'success'} /> : null}
        {text ? <span className="font-sm">{text}</span> : null}
      </div>
      : null
  )
};

CountDown.defaultProps = {
  count: 0,
  showProgress: false,
  showCounter: true,
  className: 'countdown',
  onStop: null,
  id: null
};

CountDown.propTypes = {
  count: PropTypes.number.isRequired,
  showProgress: PropTypes.bool.isRequired,
  showCounter: PropTypes.bool.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  onStop: PropTypes.func,
  id: PropTypes.string
};

export default CountDown;
