import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withApollo } from 'react-apollo';
import { reduxForm } from "redux-form";
import { notificationErrorSimple } from 'redux/alerts/actions';
import Loader from 'components/common/loader/Loader';
import Modal from 'components/modal/Modal';
import { UNWIND_CANCELATION_SECONDS } from 'constants.js';
import OpenTradesUnwindForm from './OpenTradesUnwindForm';
import { OPEN_TRADE_UNWIND_QUERY } from './query';
import { OPEN_TRADES_FORM_TRADE_UNWIND } from "./constants";

class OpenTradesUnwind extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      unwindHistoryId: null,
      disableOnToggle: true,
      items: [],
      summary: {},
    };
  }

  componentDidMount() {
    this.unwindProcessMutation();
  }

  unwindProcessMutation = async () => {
    const { client, selectedIds, notificationErrorSimple, setUnwindIdAction } = this.props;
    try {
      const response = await client.mutate({
        mutation: OPEN_TRADE_UNWIND_QUERY,
        variables: {
          externalIds: [...selectedIds]
        }
      });

      if (response?.data?.unwindOpenTradesMutation) {
        this.setState({
          loading: false,
          unwindHistoryId: response.data.unwindOpenTradesMutation.unwindHistoryId,
          items: response.data.unwindOpenTradesMutation.items,
          summary: response.data.unwindOpenTradesMutation.summary,
          disableOnToggle: false,
        });
        setUnwindIdAction(response.data.unwindOpenTradesMutation.unwindHistoryId)
      }
      return response.data;
    } catch (e) {
      this.onClosePopup()
      notificationErrorSimple(e?.graphQLErrors[0]?.message)
      console.log(e.graphQLErrors);
    }
  }

  onClosePopup = () => {
    const { onClose, submitSucceeded } = this.props;
    const { unwindHistoryId } = this.state
    onClose(unwindHistoryId, submitSucceeded)
  }

  render() {
    const { unwindProcess, setUnwindIdAction } = this.props;
    const { loading, items, summary, unwindHistoryId, disableOnToggle } = this.state
    return (
      <Modal
        isOpen={unwindProcess}
        onClose={this.onClosePopup}
        onToggle={this.onClosePopup}
        disableOnToggle={disableOnToggle}
        component={
          loading ?
            <Loader /> :
            <OpenTradesUnwindForm
              countDown={UNWIND_CANCELATION_SECONDS}
              onClose={this.onClosePopup}
              items={items}
              summary={summary}
              unwindHistoryId={unwindHistoryId}
              setUnwindIdAction={setUnwindIdAction}
            />
        }
      />
    )
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  notificationErrorSimple,
}, dispatch);

const reduxFormOptions = {
  form: OPEN_TRADES_FORM_TRADE_UNWIND,
};

export default compose(
  withApollo,
  reduxForm(reduxFormOptions),
  connect(null, mapDispatchToProps),
)(OpenTradesUnwind);
