import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Error from '@material-ui/icons/Error';
import { Button } from 'reactstrap';
import './error.css';

export const reloadPage = () => {
  window.location.reload(true);
}

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    message: null
  };

  refreshPage = () => {
    reloadPage();
  }

  componentDidCatch(error) {
    const { message } = error;
    const cleanedMessage = message.replace(/GraphQL error: /, '');
    this.setState({ hasError: true, message: cleanedMessage });
  }

  render() {
    const { hasError, message } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="text-center text-danger error-boundary">
          <div className="mb-4 h4">
            <Error className="mr-2 align-middle" />
            {message ?
              message :
              <FormattedMessage id="common/server-error"
                defaultMessage="Server Error."
              />}
          </div>
          <div className="text-center">
            <Button color="primary" onClick={this.refreshPage} size="sm">
              <FormattedMessage id="form.custom-pricing.submit"
                defaultMessage="Reload page" />
            </Button>
          </div>
        </div>
      )
    }
    return children;
  }
}

export default ErrorBoundary;
