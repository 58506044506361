import React, { ReactNode, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";
import AG_GRID_LICENCE from "./AgGridLicence";
import "./Grid.css";

LicenseManager.setLicenseKey(AG_GRID_LICENCE);

type RowData = {
  [field: string]: string | number | boolean;
} & {
  cellRenderer?: ReactNode;
};

interface GridProps {
  columnDefs: {
    field: string;
    headerName?: string;
    valueGetter?: (fields: any) => string;
    valueFormatter?: (fields: any) => string;
    sortable?: boolean;
    filter?: boolean;
    floatingFilter?: boolean;
    flex?: number;
  }[];
  rowData: RowData[];
}

const Grid: React.FC<GridProps> = ({ rowData, columnDefs }) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: true,
      pivot: true,
    }),
    [],
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  return (
    <div style={containerStyle}>
      <div className="ag-theme-alpine" style={{ height: "100%" }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          pagination={true}
          paginationAutoPageSize={true}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

export default Grid;
